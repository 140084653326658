<template>
  <v-container fluid>
    <v-app-bar dense app  height="56px">
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click="mini = !mini"></v-app-bar-nav-icon>
      </div>
      <v-spacer></v-spacer>
      <span>{{$store.getters.getUserState.firstName }} {{$store.getters.getUserState.lastName }}</span>
      <v-dialog
        v-model="changePasswordDialog"
        transition="dialog-bottom-transition"
        max-width="600"
        persistent
        style="z-index: 9"
      >
        <template>
          <v-card class="border-0">
            <v-toolbar
              color="primary"
              class="tw-text-white mb-10"
            >Change password dialog</v-toolbar
            >
            <v-card-text>
                <v-container>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-text-field
                        v-model="passwordDetails.password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        label="Old Password *"
                        required
                        dense
                        prepend-icon="mdi-lock"
                        @click:append="show = !show"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-text-field
                        v-model="passwordDetails.password1"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show1 ? 'text' : 'password'"
                        label="New Password *"
                        required
                        dense
                        prepend-icon="mdi-lock"
                        hint="At least 8 characters"
                        :rules="[rules.required, rules.min]"
                        @click:append="show1 = !show1"
                      />
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="12">
                      <v-text-field
                        v-model="passwordDetails.password2"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show2 ? 'text' : 'password'"
                        label="Confirm New Password *"
                        required
                        dense
                        prepend-icon="mdi-lock"
                        hint="At least 8 characters"
                        :rules="[rules.required, rules.min]"
                        @click:append="show2 = !show2"
                      />
                    </v-col>
                    <v-col cols="12" md="12">
                      <span v-if="passwordDetails.password2.length>0|| passwordDetails.password1.length>0">
                        <p v-if="passwordDetails.password1==passwordDetails.password2" style="color:green"><v-icon color="success">mdi-check-circle</v-icon> Password Matched</p>
                      <p v-else style="color:red"><v-icon color="error">mdi-close-circle</v-icon> Password Not Matched</p>
                      </span>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-end">
              <span v-if="passwordDetails.password2.length>0|| passwordDetails.password1.length>0">
                <v-btn
                  color="success"
                  elevation="0"
                  class="rounded-lg"
                  dense
                  text
                  v-if="passwordDetails.password1 === passwordDetails.password2 && passwordDetails.password.length>0"
                  @click="updatePassword()"

                ><v-icon small>mdi-check-circle</v-icon> Save</v-btn
                ></span>
              <v-btn
                color="error"
                elevation="0"
                class="rounded-lg"
                dense
                text
                @click="closeChangePassword"
              ><v-icon small>mdi-close</v-icon> Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>



      <v-menu dense text offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" >
            <v-avatar color="#0c2e4c" size="35">
              <span class="white--text text-h6 dense text-capitalize">{{$store.getters.getUserState.username.charAt(0)}}</span>
            </v-avatar>
            <v-icon right color="grey"> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-list dense>

          <v-list-item @click="changePassword">
            <v-list-item-icon>
              <v-icon left>mdi-account-cog</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            router
            :to="item.route"
            link
          >
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logoutHandler">
            <v-list-item-icon>
              <v-icon left>mdi-power</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <SideBar :mini="mini" />
  </v-container>
</template>

<script>

import SideBar from "./SideBar.vue";
import revokeToken from "@/GraphQL/Auth/revokeToken.graphql"
import passwordChange from "@/GraphQL/Auth/passwordChange.graphql"
export default {
  components: { SideBar },
  data() {
    return {
      mini: false,
      fav: true,
      menu: false,
      message: false,
      show:false,
      show1:false,
      show2:false,
      passwordDetails:{
        password1: "",
        password2: "",
        password: ""
      },
      hints: true,
      changePasswordDialog:false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      items: [
        // { icon: "mdi-account-cog", title: "Change Password", route: "" },
        { icon: "mdi-help-circle", title: "Help", route: "" },
        // { icon: "mdi-power", title: "Logout", route: "/logout" },
      ],
    };
  },
  computed:{
   username() {
      return localStorage.getItem("logged$store.getters.getUserState.username")
    }
  },
  methods:{
    logoutHandler() {

      // console.log(this.$store.getters.getRefreshToken);
      this.$apollo.mutate({
        mutation:revokeToken,
        variables:{refreshToken:this.$store.getters.getRefreshToken}
      }).then(response=>{
        let {success,errors,revoked}= response.data.revokeToken
        console.log(success,errors,revoked);
          this.$store.commit("setTokenState", null);
          this.$router.push("/")
      })
    },
    changePassword(){
      this.changePasswordDialog=true
    },
    closeChangePassword(){
      this.changePasswordDialog = false;
      this.passwordDetails.password = "";
      this.passwordDetails.password1 = "";
      this.passwordDetails.password2 = "";
    },
    updatePassword(){
      this.$apollo.mutate({
        mutation: passwordChange,
        variables:this.passwordDetails
      }).then(response=>{
        if(response.data.passwordChange.success){
          localStorage.removeItem("token");
          localStorage.removeItem("logged$store.getters.getUserState.username");
          localStorage.removeItem("loggedUserId");
          // this.$swal({icon:"error",text:"Logout Failed!, Please try "});
          this.$router.push("/")
        }else{

          this.$swal({icon:"error",text:"Password Change Failed,Please try again!"});
        }
      })
    }
  }
};
</script>
<style>
v-btn:hover:before {
  background-color: transparent;
}
</style>
