<template>
  <v-container>
    <v-app-bar dense app   color="blue-grey darken-4">
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click="mini = !mini"></v-app-bar-nav-icon>

        <!-- <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
      </div>
      <v-spacer></v-spacer>
      <v-menu dense flat offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn plain color="white" dark v-bind="attrs" v-on="on">
            <span color="white"> Edwin Beatus</span>
            <v-icon right color="grey"> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="(item, index) in items" :key="index" link>
            <v-list-item-icon>
              <v-icon left>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <SideBar :mini="mini" />
  </v-container>
</template>

<script>
import SideBar from "./SideBar.vue";

export default {
  components: { SideBar },
  data() {
    return {
      mini: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      items: [
        { icon: "mdi-account-tie", title: "My Profile" },
        { icon: "mdi-help-circle", title: "Help" },
        { icon: "mdi-power", title: "Logout" },
      ],
    };
  },
};
</script>
<style>
v-btn:hover:before {
  background-color: transparent;
}
</style>
