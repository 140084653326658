import { apolloClient } from "@/vue-apollo";
import getAllUsers from "@/GraphQL/UserManagement/getAllUsers.graphql";
import register from "@/GraphQL/UserManagement/register.graphql";
import updateAccount from "@/GraphQL/UserManagement/updateAccount.graphql";
import softDeleteUser from "@/GraphQL/UserManagement/softDeleteUser.graphql";
import Vue from "vue";

export default {
  state: {
    users: [],
  },
  getters: {
    getUsers: (state) => state.users,
  },
  mutations: {
    loadUsers(state, userDetails) {
      state.users.push(userDetails);
    },
    deleteUser: (state, data) => (state.users.splice(state.users.findIndex(({ id }) => id === data.id),
      1
    )),
    editUser(state,data){
      if (state.users.find(({ id }) => id === data.id)) {
        state.users.find(
          ({ id }) => id === data.id
        ).firstName = data.firstName;
        state.users.find(
          ({ id }) => id === data.id
        ).lastName = data.lastName;
        state.users.find(
          ({ id }) => id === data.id
        ).email = data.email;

      }
    },
    clearUsers(state) {
      state.users = [];
    },
  },
  actions: {
    async loadUsers(context) {
      context.commit("clearUsers");
      context.commit("setLoadingState", true);
      apolloClient
        .query({
          query: getAllUsers,
        })
        .then((response) => {
          response.data.getAllUsers?.forEach((element) => {
            let data = {
              id: element.id,
              firstName: element.firstName,
              lastName: element.lastName,
              email: element.email,
            };
            context.commit("loadUsers", data);
          });
        }).finally(()=>{context.commit("setLoadingState", false);});
    },
    async register(context, data) {
      // console.log("CREATE UPDATE",data);
      context.commit("setLoadingState", true, { root: true });
      apolloClient
        .mutate({
          mutation: register,
          variables: data,
        })
        .then((response) => {
          let { errors, success } = response.data.register;
          console.log(errors);
          // console.log(token);
          success?apolloClient.mutate({
            mutation: updateAccount,
            variables: data
          }).then((response)=>{
            let success2=response.data.updateUserAfterRegister.success
            let element=response.data.updateUserAfterRegister.createUserRole
            // console.log(data);
            context.commit("setLoadingState", false, { root: true });
            if (!success2) {
              Vue.swal({icon:"error",title:"Error",text:"Error in creating user"});
            } else {
              let data = {
                id: element.id,
                firstName: element.firstName,
                lastName: element.lastName,
                email: element.email,
              };
              context.commit("loadUsers", data);
              Vue.swal({icon:"success",title:"Success!",text:"User created Successfully!"});
            }
            return data;
          }):Vue.swal({icon:"error",title:"Error!",text:"Error in creating user"});
        });
    },
    async updateUser(context, data) {
      // console.log("CREATE UPDATE",data);
      context.commit("setLoadingState", true, { root: true });
      apolloClient.mutate({
            mutation: updateAccount,
            variables: data
          }).then((response)=>{
            let success2=response.data.updateUserAfterRegister.success
            let element=response.data.updateUserAfterRegister.createUserRole
            // console.log(data);
            context.commit("setLoadingState", false, { root: true });
            if (!success2) {
              Vue.swal({icon:"error",title:"Error",text:"Error in updating user"});
            } else {
              let data = {
                id: element.id,
                firstName: element.firstName,
                lastName: element.lastName,
                email: element.email,
              };
              context.commit("editUser", data);
              Vue.swal({icon:"success",title:"Success!",text:"User updated Successfully!"});
            }
            return data;
          });

    },

    async deleteUser(context, data) {
      context.commit("setLoadingState", true);
      // console.log(data);
      apolloClient
        .mutate({
          mutation: softDeleteUser,
          variables: data,
        })
        .then((response) => {
          let { success } = response.data.softDeleteUser;
          context.commit("setLoadingState", false);
          if (success) {
            Vue.swal({icon:"success",title:"Success!",text:"User deleted Successfully!"});
            context.commit("deleteUser", data);
          } else {
            Vue.swal({icon:"error",title:"Error!",text:"Error in deleting User"});
          }
        });
    },
  },
};
