<template>
  <v-app>
      <v-container class="fill-height" fluid>
        <ToolBar />
      </v-container>
  </v-app>
</template>

<script>
import ToolBar from "../Shared_Report/Navigation/ToolBar.vue";
export default {
  components: {
    ToolBar,
  },
};
</script>

<style></style>
