<template>
  <v-app>
    <ToolBar />
    <v-card class="pa-5 px-0 rounded-xl mx-10" elevation="0">
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <div class="d-flex justify-lg-space-between">
            <v-btn
              @click="$router.push({ name: 'Projects' })"
              color="primary"
              class="text-capitalize rounded-lg"
              ><v-icon small>mdi-arrow-left-circle</v-icon> Projects</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              @click="
                $router.push({
                  name: 'Reporting Page',
                  params: { projectId: $route.params.projectId },
                })
              "
              color="primary"
              class="text-capitalize rounded-lg"
              ><v-icon small>mdi-script</v-icon> Reporting</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="pa-5 px-10 rounded-xl mx-10" elevation="0" color="#eeeeff">
      <v-row>
        <v-col cols="12" sm="12" md="8">
          <h5>Project Name:</h5>
          <h1 class="text-capitalize text--primary">
            {{ oneProject[0].projectName }}
          </h1>
          <p>
            <b>Start-date:</b> {{ oneProject[0].projectStartDate }} &nbsp;&nbsp;
            <b>Expected End-date:</b> {{ oneProject[0].projectEndDate }}
          </p>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <b>Project Status:&nbsp;</b>
          <v-chip class="mb-2" small color="primary" pill>
            {{ oneProject[0].projectStatus.projectStatusName }}
          </v-chip>
          <p><b>Client/Institution:</b> {{ oneProject[0].projectClient }}</p>
          <p>
            <b>All Tasks(This week): </b>{{ $store.getters.getTasks.length }}
          </p>
        </v-col>
      </v-row> 
    </v-card>
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-card
          class="pa-5 px-10 rounded-xl mx-10 mt-8"
          elevation="0"
          color="#eeeeee"
        >
          <h5>Project Description:</h5>
          <p>{{ oneProject[0].projectDescription }}</p>
          <br />
          <v-divider></v-divider>
          <br />
          <v-row>
            <v-col
              cols="12"
              sm="12"
              class="d-md-flex justify-md-space-between mt-0"
            >
              <h5>Project Team:</h5>

              <v-btn
                color="primary"
                small
                class="rounded-lg text-capitalize mb-2"
                ><v-icon small>mdi-plus</v-icon> Team Member</v-btn
              >
            </v-col>
          </v-row>
          <ol>
            <li
              v-for="projectMember in oneProject[0].projectMembers"
              :key="projectMember.username"
              class="text-capitalize"
            >
              {{ projectMember.firstName }} {{ projectMember.lastName }}
              <span
                v-for="projectLeaderSingle in oneProject"
                :key="projectLeaderSingle.projectLeader.username"
              >
                <v-icon
                  color="success"
                  v-if="
                    projectMember.username ===
                    projectLeaderSingle.projectLeader[0].username
                  "
                  >mdi-crown-circle</v-icon
                >
              </span>
            </li>
          </ol>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-card
          class="pa-5 px-10 rounded-xl mx-10 mt-8"
          elevation="0"
          color="#eeeeee"
        >
          <v-row>
            <v-col
              cols="12"
              sm="12"
              class="d-md-flex justify-md-space-between mt-0 mb-3"
            >
              <h3>This Week Report:</h3>
            </v-col>
          </v-row>
          <h4>This Week Activities:</h4>
          <ol class="mb-4">
            <span
              v-for="singleTask in $store.getters.getTasks"
              :key="singleTask.taskId"
              style="background: transparent; padding: 0"
            >
              <li v-if="!singleTask.isNextWeekTask" class="text-capitalize">
                {{ singleTask.taskDescription }}
                <v-icon v-if="singleTask.taskStatus" color="success"
                  >mdi-check-circle</v-icon
                >
              </li>
            </span>
          </ol>
          <v-divider></v-divider>

          <h4 class="mt-4">Next Week Activities:</h4>
          <ol class="mb-4">
            <span
              v-for="singleTask in $store.getters.getTasks"
              :key="singleTask.taskId"
              style="background: transparent; padding: 0"
            >
              <li v-if="singleTask.isNextWeekTask" class="text-capitalize">
                {{ singleTask.taskDescription }}
              </li>
            </span>
          </ol>
          <br />
          <v-icon color="success">mdi-check-circle</v-icon>
          Completed&nbsp;&nbsp; <v-icon>mdi-circle-outline</v-icon> In-Progress
        </v-card>
      </v-col>
      <v-col cols="12" md="12" class="mx-10" v-if="$store.getters.getAttachments.length">
        <v-divider></v-divider>
        <h5>Attachments This week:</h5>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        v-for="item in $store.getters.getAttachments"
        :key="item.filePath"
        style="padding: 0"
      >
        <v-list-item dense class="mx-10">
          <v-list-item-content class="pa-0 ma-0">
            <div
              class="d-flex justify-space-between align-center px-4 mb-1 py-1 rounded-lg"
              style="background: #ddd; min-height: 40px"
            >
              <v-list-item-icon
              ><img
                :src="
                        $store.getters.getIcons[
                          item.fileExtension.split('.')[1]
                        ]
                          ? $store.getters.getIcons[
                              item.fileExtension.split('.')[1]
                            ]
                          : $store.getters.getIcons['file']
                      "
                height="30"
              /></v-list-item-icon>
              <v-list-item-action-text style="width: 100%">
                {{ item.fileName }}<br /><span class="grey--text"
              ><b>Created On:</b>
                      {{ item.createdDate.substr(0, 10) }}</span
              ></v-list-item-action-text
              >

              <a
                :href="backend_url + item.filePath"
                style="text-decoration: none"
                :download="item.fileName"
                target="_blank"
              ><v-icon small color="primary">mdi-download-box</v-icon></a
              >
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import ToolBar from "../weris_shared/navigations/ToolBar.vue";
import oneProject from "../../../GraphQL/Projects/oneProject.gql";
import { jsontoexcel } from "vue-table-to-excel";
export default {
  components: {
    ToolBar,
  },
  apollo: {
    oneProject: {
      query: oneProject,
      update: (data) => {
        // console.log(data);
        return [data.oneProject];
      },
      variables() {
        return {
          projectId: this.$route.params.projectId,
        };
      },
    },
  },
  data() {
    return {
      search: "",
      start_date: "",
      headers: [
        { text: "Sn", align: "start", sortable: false, value: "SN" },
        { text: "Project Activity", value: "projectName", sortable: false },
        { text: "Client/Institution", value: "projectClient", sortable: false },
        { text: "Description", value: "projectDescription", sortable: false },
        { text: "Status", value: "projectStatus", sortable: true },
        { text: "Start Date", value: "projectStartDate", sortable: false },
        { text: "Completion Date", value: "projectEndDate", sortable: false },
        { text: "Project Team", value: "projectMembers", sortable: false },
        {
          text: "Actions Taken During the Week and Plan",
          value: "taskProject",
          sortable: false,
        },
        { text: "Project Comments", value: "projectComments", sortable: false },
      ],
      heads: [
        "Project Activity",
        "Client/Institution",
        "Description",
        "Project Team",
        "Status",
        "Project Comments",
        "Start Date",
        "Expected Completion Date",
      ],
      //    date:new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu1: false,
      menu2: false,
    };
  },
  methods: {
    export_data_to_exel() {
      jsontoexcel.getXlsx(this.projects, this.heads, "projects_report.xls");
    },
  },
  computed: {
    filteredProjects() {
      return this.start_date == ""
        ? this.projects
        : this.projects.filter((p) => p.start_date == this.start_date);
    },
  },
  mounted() {
    this.$store.dispatch("loadTasks", this.$route.params.projectId);
    // console.log("project ID from route", this.$route.params.projectId);
  },
};
</script>

<style>
p {
  margin: 0 !important;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}

.card {
  all: unset;
  border-radius: 20px;
  border: 1px solid #eee;
  background-color: #fafafa;
  height: 40px;
  width: 200px;
  margin: 0 8px 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  line-height: 24px;
}

.card-container .card:not(:last-child) {
  margin-right: 0;
}

.card.card-small {
  height: 16px;
  width: 168px;
}

.card-container .card:not(.highlight-card) {
  cursor: pointer;
}

.card-container .card:not(.highlight-card):hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
}

.card-container .card:not(.highlight-card):hover .material-icons path {
  fill: rgb(105, 103, 103);
}

.card.highlight-card {
  background-color: #1976d2;
  color: white;
  font-weight: 600;
  border: none;
  width: auto;
  min-width: 30%;
  position: relative;
}

.card.card.highlight-card span {
  margin-left: 60px;
}
</style>
