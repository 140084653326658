import Vue from "vue";
import Vuex from "vuex";
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate";
import projectCategoryStore from "@/store/projectCategoryStore";
import projectStatusStore from "@/store/projectStatusStore.js";
import projectMaturityStore from "@/store/projectMaturityStore.js";
import taskManagementStore from "@/store/taskManagementStore";
import projectStore from "@/store/projectStore";

import roleManagementStore from "@/store/roleManagementStore";
import userManagementStore from "@/store/userManagementStore";
import authStore from "@/store/authStore";
import refreshToken from "@/GraphQL/Auth/refreshToken.graphql";
import router from "@/router/index.js";
import store from "@/store/index.js"
import { apolloClient } from "@/vue-apollo";
import revokeToken from "@/GraphQL/Auth/revokeToken.graphql";

let ls = new SecureLS({
  encodingType: "aes",
  isCompression: true,
  encryptionSecret: process.env.VUE_APP_STR_PWD,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    idleVue: null,
    drawer: false,
    nightMode: false,
    user: null,
    refreshToken: "",
    token: "",
    icons: {
      php: "/icons/php.png",
      pdf: "/icons/pdf.png",
      xls: "/icons/xls.png",
      xlsx: "/icons/xls.png",
      jpg: "/icons/jpg.png",
      file: "/icons/file.png",
    },
  },
  getters: {
    getLoadingState: (state) => state.isLoading,
    getUserState: (state) => state.user,
    getRefreshToken: (state) => state.refreshToken,
    getTokenState: (state) => state.token,
    getSideNavDrawer: (state) => state.drawer,
    getNightMode: (state) => state.nightMode,
    getIcons: (state) => state.icons,
  },
  mutations: {
    setLoadingState: (state, loading) => {
      state.isLoading = loading;
    },
    setRefreshTokenState: (state, token) => {
      state.refreshToken = token;
    },
    setTokenState: (state, token) => {
      state.token = token;
    },
    setUserState: (state, token) => {
      state.user = token;
    },
    setSideNavDrawer: (state, drawer) => {
      state.drawer = drawer;
    },
    setNightMode: (state, mode) => {
      state.nightMode = mode;
    },
  },
  actions: {
    logoutHandler(context) {
      apolloClient
        .mutate({
          mutation: revokeToken,
          variables: { refreshToken: store.getters.getRefreshToken },
        })
        .then((response) => {
          let { success, errors, revoked } = response.data.revokeToken;
          console.log(success, errors, revoked);
          context.commit("setTokenState", null);
          router.push("/");
        });
    },
    displayErrorSuccessMessages(data) {
      if (data.error) {
        Vue.swal({
          toast: true,
          position: "top-right",
          title: "Error: " + data.code + "!",
          text: data.message,
          timerProgressBar: true,
          timer: 10000,
          icon: "error",
          showConfirmButton: false,
          animation: true,
        });
      } else {
        Vue.swal({
          toast: true,
          position: "top-right",
          title: "Success!",
          text: data.message,
          timerProgressBar: true,
          timer: 10000,
          icon: "success",
          showConfirmButton: false,
          animation: false,
        });
      }
    },
    async refreshToken(context, rt) {
      await apolloClient
        .mutate({
          mutation: refreshToken,
          variables: { refreshToken: rt },
        })
        .then((output) => {
          let { token, refreshToken, success, errors } =
            output.data.refreshToken;
          // console.log(token, refreshToken, success, errors);
          if (success) {
            context.commit("setTokenState", token);
            context.commit("setRefreshTokenState", refreshToken);
          } else {
            console.log("Refresh", errors.nonFieldErrors);
            context.dispatch("displayErrorSuccessMessages", {
              error: true,
              code: errors.nonFieldErrors[0].code,
              message: "Session has expired",
            });
            context.commit("setTokenState", null);
            context.commit("setRefreshTokenState", null);
            setTimeout(() => {
              router.push("/project/dashboard");
            }, 500);
          }
        });
    },
  },
  modules: {
    // userManagementStore,
    taskManagementStore,
    projectCategoryStore,
    projectStore,
    roleManagementStore,
    userManagementStore,
    authStore,
    projectStatusStore,
    projectMaturityStore
  },
  plugins: [
    createPersistedState({
      key: "EG",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
      reducer(val) {
        if (val.token === null) {
          // val.user.token (your user token for example)
          return {};
        }
        // console.log(val.token)
        return val;
      },
    }),
  ],
});
