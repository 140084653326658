<template>
  <div class="main-container">
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12">
          <div class="d-flex justify-space-between mb-4">
            <v-btn
              to="/project/dashboard"
              color="primary"
              class="text-capitalize rounded-lg"
            >
              <v-icon small>mdi-arrow-left-circle</v-icon>
              Dashboard
            </v-btn>
            <v-btn
              small
              left
              fab
              color="primary"
              dark
              dense
              @click="openCreateProjectDialog"
              v-if="$store.getters.getUserState.isStaff"
            >
              <v-icon small> mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <CreateNewProjects
              v-bind="attrs"
              v-on="on"
              :CreateUpdateProjectDialog="createUpdateProjectDialog"
              :CreateUpdateProjectData="createUpdateProjectData"
              @close="createUpdateProjectDialog = false"
            >
            </CreateNewProjects>
          </template>
          <span>Create New Project</span>
        </v-tooltip>
      </v-row>
      <br />
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="$store.getters.getProjects"
          :search="search"
        >
          <template v-slot:item.SN="{ item, index }" style="max-width:10px">
            {{ index + 1 }}
          </template>
          <template v-slot:item.creationDate="{ item }">
            {{ item.creationDate | formatDate }}
          </template>
          <template v-slot:item.projectName="{ item }">
            <v-list-item two-line style="max-width:450px">
              <v-list-item-content >
                <v-list-item-title>{{ item.projectName }}</v-list-item-title>
                <p class="text-caption">{{ item.projectDescription }}</p>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:item.projectMembers="{ item }">
            <v-list-item
              v-for="projectMember in item.projectMembers"
              :key="projectMember.username"
            >
              {{ projectMember.firstName }}
              {{ projectMember.lastName }}
              <v-icon
                v-if="
                  item.projectLeader.find(
                    ({ username }) => username === projectMember.username
                  )
                "
                color="primary"
                >mdi-crown-circle</v-icon
              >
            </v-list-item>
          </template>

          <template v-slot:item.projectStatus="{ item }">
            <v-list-item
              v-for="status in item.projectStatus"
              :key="status.projectStatusUniqueId"
            >
              {{ status.projectStatusName }}
            
             
            </v-list-item>
          </template>

          <template v-slot:item.action="{ item }">
            <v-menu dense offset-x :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  right
                  :nudge-width="200"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  @click="updateProject(item)"
                  v-if="$store.getters.getUserState.isStaff"
                >
                  <v-list-item-icon>
                    <v-icon color="#5d9ad0">mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Edit Project</v-list-item-title>
                </v-list-item>
                <v-list-item @click="reportProject(item)">
                  <v-list-item-icon>
                    <v-icon left color="#5d9ad0">mdi-note-check</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Reporting</v-list-item-title>
                </v-list-item>
                <v-list-item @click="viewOneProject(item.projectId)">
                  <v-list-item-icon>
                    <v-icon color="#5d9ad0" left>mdi-eye</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>View Details</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="deleteProject(item.projectId)"
                  v-if="$store.getters.getUserState.isStaff"
                >
                  <v-list-item-icon>
                    <v-icon color="#f83e70" left small>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Delete Project</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import CreateNewProjects from "../../Projects/weris_projects/CreateNewProject.vue";
export default {
  data() {
    return {
      search: "",
      createUpdateProjectDialog: false,
      createUpdateProjectData: null,
      items: [{ icon: "mdi-eye", title: "Reports", route: "/project_report" }],
      headers: [
        {
          text: "Sn",
          align: "start",
          sortable: false,
          value: "SN",
          width: "20%",
        },
        {
          text: "Project Title",
          align: "start",
          value: "projectName",
          sortable: false,
          width: "20%",
        },
        {
          text: "Project Status",
          value: "projectStatus.projectStatusName",
          sortable: false,
          width: "20%",
        },
        {
          text: "Project Members",
          value: "projectMembers",
          sortable: false,
          width: "20%",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          width: "20%",
        },
      ],
    };
  },
  methods: {
    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    deleteProject(projectId) {
      let data = {
        projectId: projectId,
      };
      this.$store.dispatch("deleteProject", data);
    },
    viewOneProject(projectId) {
      // let data = {
      //   projectId,
      // };
      // console.log(data);
      this.$router.push({
        name: "Project Detailed View",
        params: { projectId: projectId },
      });
    },
    reportProject(project) {
      this.$router.push("/reporting_page/" + project.projectId + "/"+project.projectName);
    },
    updateProject(items) {
      this.createUpdateProjectDialog = true;
      this.createUpdateProjectData = items;
      this.$store.dispatch("loadProjectCategories");
    },
    openCreateProjectDialog() {
      this.createUpdateProjectDialog = true;
      this.$store.dispatch("loadProjectCategories");
    },
  },
  mounted() {
    this.$store.dispatch("loadProject");
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("loadRoles");
    this.search = this.$route.params?.search;
  },
  components: { CreateNewProjects },
  computed: {
    username() {
      // return localStorage.getItem("loggedusername");
      // console.log();
      return localStorage.getItem("fetchUserPermissions");
      // localStorage.dispatch("fetchUserPermissions");
    },
  },
};
</script>

<style>
.project.Completed {
  border-left: 4px solid #3cd1c2;
}
.project.Ongoing {
  border-left: 4px solid orange;
}
.project.overdue {
  border-left: 4px solid tomato;
}
#chip.v-chip.Completed {
  background: #3cd1c2;
}
#chip.v-chip.Ongoing {
  background: #ffaa2c;
}
#chip.v-chip.Overdue {
  background: #f83e70;
}
</style>
