import { apolloClient } from "@/vue-apollo";
import allTasksForAProjectPerUser from "@/GraphQL/Tasks/allTasksForAProjectPerUser.gql";
import createUpdateTask from "@/GraphQL/Tasks/CreateUpdateTask.gql";
import DeleteTask from "@/GraphQL/Tasks/DeleteTask.graphql";
import getAttachmentsByProjectId from "@/GraphQL/Tasks/getAttachmentsByProjectId.graphql";
import deleteAttachment from "@/GraphQL/Tasks/deleteAttachment.graphql";

import getProjectWeeklySummary from "@/GraphQL/Tasks/getProjectWeeklySummary.graphql";
import createUpdateWeeklySummary from "@/GraphQL/Tasks/createUpdateWeeklySummary.graphql";
import deleteWeeklySummary from "@/GraphQL/Tasks/deleteWeeklySummary.graphql";
import createUpdateWeeklyBlockingIssue from "@/GraphQL/BlockingIssues/createUpdateWeeklyBlockingIssue.gql";
import deleteBlockingIssues from "../GraphQL/BlockingIssues/deleteProjectBlockingIssue.gql"
export default {
  state: {
    task: [],
    attachments: [],
    weeklySummary: [],
    weeklyBlockingIssues: [],
  },
  getters: {
    getTasks: (state) => state.task,
    getAttachments: (state) => state.attachments,
    getProjectWeeklySummary: (state) => state.weeklySummary,
    getProjectWeeklyBlockingIssues: (state) => state.weeklyBlockingIssues,
  },
  mutations: {
    loadTasks(state, taskDetails) {
      if (state.task.find(({ taskId }) => taskId === taskDetails.taskId)) {
        state.task.find(
          ({ taskId }) => taskId === taskDetails.taskId
        ).taskDescription = taskDetails.taskDescription;
        state.task.find(
          ({ taskId }) => taskId === taskDetails.taskId
        ).isDeletableOrEditable = taskDetails.isDeletableOrEditable;
        state.task.find(
          ({ taskId }) => taskId === taskDetails.taskId
        ).isNextWeekTask = taskDetails.isNextWeekTask;
        state.task.find(
          ({ taskId }) => taskId === taskDetails.taskId
        ).projectId = taskDetails.projectId;
        state.task.find(
          ({ taskId }) => taskId === taskDetails.taskId
        ).taskStatus = taskDetails.taskStatus;
      } else {
        state.task.push(taskDetails);
      }
    },
    clearTasks(state) {
      state.task = [];
    },
    loadWeeklySummaries(state, itemDetails) {
      if (
        state.weeklySummary.find(
          ({ summaryId }) => summaryId === itemDetails.summaryId
        )
      ) {
        state.weeklySummary.find(
          ({ summaryId }) => summaryId === itemDetails.summaryId
        ).description = itemDetails.description;
        state.weeklySummary.find(
          ({ summaryId }) => summaryId === itemDetails.summaryId
        ).isDeletableOrEditable = itemDetails.isDeletableOrEditable;
        state.weeklySummary.find(
          ({ summaryId }) => summaryId === itemDetails.summaryId
        ).isNextWeekTask = itemDetails.isNextWeekTask;
        state.weeklySummary.find(
          ({ summaryId }) => summaryId === itemDetails.summaryId
        ).projectId = itemDetails.projectId;
        state.weeklySummary.find(
          ({ summaryId }) => summaryId === itemDetails.summaryId
        ).taskStatus = itemDetails.taskStatus;
      } else {
        state.weeklySummary.push(itemDetails);
      }
    },
    clearWeeklySummary(state) {
      state.weeklySummary = [];
    },
    //blocking issues
    loadWeeklyBlockingIssues(state, itemDetails) {
      if (
        state.weeklyBlockingIssues.find(
          ({ blockingIssueId }) => blockingIssueId === itemDetails.blockingIssueId
        )
      ) {
        state.weeklyBlockingIssues.find(
          ({ blockingIssueId }) => blockingIssueId === itemDetails.blockingIssueId
        ).description = itemDetails.description;
        state.weeklyBlockingIssues.find(
          ({ blockingIssueId }) => blockingIssueId === itemDetails.blockingIssueId
        ).isDeletableOrEditable = itemDetails.isDeletableOrEditable;
        state.weeklyBlockingIssues.find(
          ({ blockingIssueId }) => blockingIssueId === itemDetails.blockingIssueId
        ).isNextWeekTask = itemDetails.isNextWeekTask;
        state.weeklyBlockingIssues.find(
          ({ blockingIssueId }) => blockingIssueId === itemDetails.blockingIssueId
        ).projectId = itemDetails.projectId;
        state.weeklyBlockingIssues.find(
          ({ blockingIssueId }) => blockingIssueId === itemDetails.blockingIssueId
        ).taskStatus = itemDetails.taskStatus;
      } else {
        state.weeklyBlockingIssues.push(itemDetails);
      }
    },
    clearWeeklyBlockinIssues(state) {
      state.weeklyBlockingIssues = [];
    },
    //end blocking issues

    clearAttachments(state) {
      state.attachments = [];
    },
    loadAttachments(state, data) {
      state.attachments.push(data);
    },
    deleteTasks(state, data) {
      state.task.splice(
        state.task.findIndex(({ taskId }) => taskId === data.taskId),
        1
      );
    },
    deleteWeeklySummary(state, data) {
      state.weeklySummary.splice(
        state.weeklySummary.findIndex(
          ({ summaryId }) => summaryId === data.summaryId
        ),
        1
      );
    },

    // delete blocking issues//
    deleteBlockingIssues(state, data) {
      state.weeklyBlockingIssues.splice(
        state.weeklyBlockingIssues.findIndex(
          ({ blockingIssueId }) => blockingIssueId === data.blockingIssueId
        ),
        1
      );
    },
    //end blocking issues//
    deleteAttachment(state, data) {
      state.attachments.splice(
        state.attachments.findIndex(
          ({ attachmentId }) => attachmentId === data.attachmentId
        ),
        1
      );
    },
  },
  actions: {
    async loadTasks(context, projectId) {
      context.commit("clearTasks");
      context.commit("setLoadingState", true);
      apolloClient
        .query({
          query: allTasksForAProjectPerUser,
          variables: {
            projectId: projectId,
            // pageParam: {
            //   size: 20,
            //   first: 0,
            // },
          },
        })
        .then((response) => {
          response.data.allTasksForAProjectPerUser?.forEach((element) => {
            let data = {
              taskId: element.taskId,
              taskDescription: element.taskDescription,
              isDeletableOrEditable: element.isDeletableOrEditable,
              isNextWeekTask: element.isNextWeekTask,
              projectId: element.projectId.projectId,
              taskStatus: element.taskStatus,
            };
            context.commit("loadTasks", data);
          });
        })
        .finally(() => {
          context.commit("setLoadingState", false);
        });
    },
    async createUpdateTask(context, data) {
      apolloClient
        .mutate({
          mutation: createUpdateTask,
          variables: data,
        })
        .then((response) => {
          // console.log("CreateUpdateTask",response.data);
          let dataTemp = {
            taskId: response.data.createUpdateTask.task.taskId,
            taskDescription:
              response.data.createUpdateTask.task.taskDescription,
            isDeletableOrEditable:
              response.data.createUpdateTask.task.isDeletableOrEditable,
            isNextWeekTask: response.data.createUpdateTask.task.isNextWeekTask,
            projectId: response.data.createUpdateTask.task.projectId.projectId,
            taskStatus: response.data.createUpdateTask.task.taskStatus,
          };
          context.commit("loadTasks", dataTemp);
          let responseMessage = null;
          if (data.taskId) {
            responseMessage = "Task created successfully!";
          } else {
            responseMessage = "Task updated successfully!";
          }

          if (!response.data.error) {
            let messageResponse = {
              error: false,
              message: responseMessage,
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
          return response.data.createUpdateTask;
        });
    },
    async deleteTasks(context, data) {
      apolloClient
        .mutate({
          mutation: DeleteTask,
          variables: data,
        })
        .then((response) => {
          if (response.data.DeleteTask.taskId) {
            context.commit("deleteTasks", data);
            let messageResponse = {
              error: false,
              message: "Task deleted successfully!",
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
    async deleteAttachment(context, data) {
      apolloClient
        .mutate({
          mutation: deleteAttachment,
          variables: data,
        })
        .then((response) => {
          if (response.data.deleteAttachment.success) {
            context.commit("deleteAttachment", data);
            let messageResponse = {
              error: false,
              message: "Attachment deleted successfully!",
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
    async loadProjectAttachments(context, projectId) {
      context.commit("clearAttachments");
      context.commit("setLoadingState", true);
      apolloClient
        .query({
          query: getAttachmentsByProjectId,
          variables: {
            projectId: projectId,
          },
        })
        .then((response) => {
          response.data.getAttachmentsByProjectId?.forEach((element) => {
            let data = {
              attachmentId: element.attachmentId,
              fileName: element.fileName,
              filePath: element.filePath,
              fileSize: element.fileSize,
              fileExtension: element.fileExtension,
              createdDate: element.createdDate,
            };
            context.commit("loadAttachments", data);
          });
        })
        .finally(() => {
          context.commit("setLoadingState", false);
        });
    },
    async loadWeeklySummaries(context, projectId) {
      context.commit("clearWeeklySummary");
      context.commit("setLoadingState", true);
      apolloClient
        .query({
          query: getProjectWeeklySummary,
          variables: {
            projectId: projectId,
            // pageParam: {
            //   size: 20,
            //   first: 0,
            // },
          },
        })
        .then((response) => {
          response.data.getProjectWeeklySummary?.forEach((element) => {
            let data = {
              summaryId: element.summaryId,
              description: element.description,
              isDeletableOrEditable: element.isDeletableOrEditable,
              isNextWeek: element.isNextWeek,
              projectId: element.projectId.projectId,
            };
            context.commit("loadWeeklySummaries", data);
          });
        })
        .finally(() => {
          context.commit("setLoadingState", false);
        });
    },
    async createUpdateWeeklySummary(context, data) {
      apolloClient
        .mutate({
          mutation: createUpdateWeeklySummary,
          variables: data,
        })
        .then((response) => {
          // console.log(response.data.createUpdateWeeklySummary);
          let { data, status } = response.data.createUpdateWeeklySummary;
          let dataTemp = {
            summaryId: data.summaryId,
            description: data.description,
            isDeletableOrEditable: data.isDeletableOrEditable,
            isNextWeek: data.isNextWeek,
            projectId: data.projectId.projectId,
          };
          context.commit("loadWeeklySummaries", dataTemp);
          let responseMessage = null;
          if (data.summaryId) {
            responseMessage = "Weekly Summary created successfully!";
          } else {
            responseMessage = "Weekly Summary successfully!";
          }

          if (status) {
            let messageResponse = {
              error: false,
              message: responseMessage,
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
          return response.data.createUpdateWeeklySummary;
        });
    },
    async deleteWeeklySummary(context, data) {
      apolloClient
        .mutate({
          mutation: deleteWeeklySummary,
          variables: data,
        })
        .then((response) => {
          if (response.data.deleteWeeklySummary.status) {
            context.commit("deleteWeeklySummary", data);
            let messageResponse = {
              error: false,
              message: "Weekly Summary deleted successfully!",
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },

    //blocking issues block //
    async createUpdateWeeklyBlockingIssue(context, data) {
      apolloClient
        .mutate({
          mutation: createUpdateWeeklyBlockingIssue,
          variables: data,
        })
        .then((response) => {
          // console.log("weelyBlocking Isusues",response.data.createUpdateWeeklyBlockingIssue.data);
          let { data, status } = response.data.createUpdateWeeklyBlockingIssue;
          let dataTemp = {
            blockingIssueId: response.data.createUpdateWeeklyBlockingIssue.data.blockingIssueId,
            description: response.data.createUpdateWeeklyBlockingIssue.data.description,
            // isDeletableOrEditable: data.isDeletableOrEditable,
            isNextWeek: response.data.createUpdateWeeklyBlockingIssue.data.isNextWeek,
            // projectId: response.data.createUpdateWeeklyBlockingIssue.data.projectId.projectId,
          };
          context.commit("loadWeeklyBlockingIssues", dataTemp);
          let responseMessage = null;
          if (data.summaryId) {
            responseMessage = "Weekly Blocking created successfully!";
          } else {
            responseMessage = "Weekly Blocking successfully!";
          }

          if (status) {
            let messageResponse = {
              error: false,
              message: responseMessage,
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
          return response.data.createUpdateWeeklySummary;
        });
    },
    async deleteBlockingIssues(context, data) {
      apolloClient
        .mutate({
          mutation: deleteBlockingIssues,
          variables: data,
        })
        .then((response) => {
            // console.log("deletete Blocking Issue",response.data.deleteProjectBlockingIssue);
          if (response.data) {
            context.commit("deleteBlockingIssues", data);
            let messageResponse = {
              error: false,
              message: "Weekly Blocking Issues deleted successfully!",
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
    // end blocking issues block//
  },
};
