<template>
      <v-container class="fill-height" fluid>   
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="8">
            <v-layout row wrap justify-space-around>
              <v-flex xs12 md4>
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    raised
                    class="text-xs-center ma-3 rounded-lg"
                    max-width="344"
                    height="150"
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                    link
                    :to="{name:`Project Reports`}"
                  >
                    <v-card max-width="344" max-height="20" color="primary">
                    </v-card>
                    <v-card-text>
                      <center>
                        <v-icon color="primary" size="80">
                          mdi-town-hall
                        </v-icon>
                        <h2 class="blue-grey--text">Weekly Reporting</h2>
                      </center>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-flex>
              <v-flex xs12 md4>
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    class="text-xs-center ma-3 rounded-lg"
                    max-width="344"
                    height="150"
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                    link
                    :to="{name:`Projects`}"
                  >
                    <v-card-text>
                      <center>
                        <v-icon color="primary" size="80">
                          mdi-projector-screen-off
                        </v-icon>
                        <h2 class="blue-grey--text">Projects</h2>
                      </center>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-flex>
              <v-flex xs12 md4>
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    class="text-xs-center ma-3 rounded-lg"
                    max-width="344"
                    height="150"
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                    link
                    to=""
                  >
                    <v-card-text>
                      <center>
                        <v-icon color="primary" size="80"> mdi-post </v-icon>
                        <h2 class="blue-grey--text">Report</h2>
                      </center>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-flex>
              <v-flex xs12 md4>
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    class="text-xs-center ma-3 rounded-lg"
                    max-width="350"
                    height="150"
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                    link
                    :to="{name:`Users`}"
                  >
                    <v-card-text>
                      <center>
                        <v-icon size="80" color="primary">
                          mdi-account-multiple-plus
                        </v-icon>
                        <h2 class="blue-grey--text">Users</h2>
                      </center>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-flex>
              <v-flex xs12 md4>
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    class="text-xs-center ma-3 rounded-lg"
                    max-width="344"
                    height="150"
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                    
                  >
                    <v-card-text>
                      <center>
                        <v-icon color="primary" size="80">
                          mdi-creation
                        </v-icon>
                        <h2 class="blue-grey--text">Self Services</h2>
                      </center>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-flex>

              <v-flex xs12 md4>
                <v-hover v-slot="{ hover }" open-delay="200">
                  <v-card
                    class="text-xs-center ma-3 rounded-lg"
                    max-width="344"
                    height="150"
                    :elevation="hover ? 16 : 2"
                    :class="{ 'on-hover': hover }"
                    link
                    to=""
                  >
                    <v-card-text>
                      <center>
                        <v-icon size="80" color="primary"> mdi-cog </v-icon>
                        <h2 class="blue-grey--text">Settings</h2>
                      </center>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-flex>
            </v-layout>
            <!-- </v-card> -->
          </v-col>
        </v-row>
      </v-container>
</template>

<script>
// import LandingPageToolBar from "./LandingPageToolBar.vue";

export default {
  components: {
    // LandingPageToolBar,
  },
  methods: {
    gotoLogin()
    {
      localStorage.removeItem("token");
      localStorage.removeItem("loggedusername");
      localStorage.removeItem("loggedUserId");
      this.$router.push("/");
    }
  }
};
</script>

<style>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  background-color: #1976d2;
}

.card {
  all: unset;
  border: 1px solid #eee;
  background-color: #fafafa;
  height: 0px;
  width: 200px;
  margin: 0 8px 16px;
  padding: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  line-height: 24px;
  border: 10px;
  border-radius: 60px;
}

.card-container .card:not(:last-child) {
  margin-right: 0;
}

.card.card-small {
  height: 16px;
  width: 168px;
}

.card-container .card:not(.highlight-card) {
  cursor: pointer;
}

.card-container .card:not(.highlight-card):hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
}

.card-container .card:not(.highlight-card):hover .material-icons path {
  fill: rgb(105, 103, 103);
}

.card.highlight-card {
  background-color: #1976d2;
  color: white;
  font-weight: 600;
  border: none;
  width: auto;
  min-width: 30%;
  position: relative;
}

.card.card.highlight-card span {
  margin-left: 60px;
}
</style>
