import { apolloClient } from "@/vue-apollo";
import DeleteMaturity from "@/GraphQL/MaturityLevel/DeleteMaturity.gql";
// eslint-disable-next-line no-unused-vars
import allMaturity from "@/GraphQL/MaturityLevel/allMaturity.gql";
import CreateProjectMaturity from "@/GraphQL/MaturityLevel/CreateMaturity.gql";


export default {
  state: {
    projectMaturity: [],
  },
  getters: {
    getProjectMaturityLevel: (state) => state.projectMaturity,
  },
  mutations: {
    loadProjectMaturity(state, projectMaturityDetails) {
      if (
        state.projectMaturity.find(
          ({ projectMaturityLevelUniqueId }) =>
            projectMaturityLevelUniqueId === projectMaturityDetails.projectMaturityLevelUniqueId
        )
      ) {
        state.projectMaturity.find(
          ({ projectMaturityLevelUniqueId }) =>
            projectMaturityLevelUniqueId === projectMaturityDetails.projectMaturityLevelUniqueId
        ).projectMaturityLevelName = projectMaturityDetails.projectMaturityLevelName;
        state.projectMaturity.find(
          ({ projectMaturityLevelUniqueId }) =>
            projectMaturityLevelUniqueId === projectMaturityDetails.projectMaturityLevelUniqueId
        ).creationDate = projectMaturityDetails.creationDate;
      } else {
        state.projectMaturity.push(projectMaturityDetails);
      }
    },
    clearProjectMaturity(state) {
      state.projectMaturity = [];
    },
    deleteProjectMaturity(state, data) {
      state.projectMaturity.splice(
        state.projectMaturity.findIndex(
          ({ projectMaturityLevelUniqueId }) =>
            projectMaturityLevelUniqueId === data.projectMaturityLevelUniqueId
        ),
        1
      );
    },
  },
  actions: {
    async loadProjectMaturity(context) {
      context.commit("clearProjectMaturity");
      context.commit("setLoadingState", true);
      apolloClient
        .query({
          query: allMaturity,
        })
        .then((response) => {
            // console.log("maturity",response.data.getProjectMaturityLevel);
          response.data.getProjectMaturityLevel?.forEach((element) => {
            let data = {
              projectMaturityLevelUniqueId: element.projectMaturityLevelUniqueId,
              projectMaturityLevelName: element.projectMaturityLevelName,
            };
            context.commit("loadProjectMaturity", data);
          });
        })
        .finally(() => {
          context.commit("setLoadingState", false);
        });
    },
    async createProjectMaturity(context, data) {
      apolloClient
        .mutate({
          mutation: CreateProjectMaturity,
          variables: data,
        })
        .then((response) => {
          // console.log("created Maturity",response.data.createProjectMaturityLevel.data);
          let dataTemp = {
            projectMaturityLevelUniqueId:response.data.createProjectMaturityLevel.data.projectMaturityLevelUniqueId,
            projectMaturityLevelName:response.data.createProjectMaturityLevel.data.projectMaturityLevelName,
          };

          console.log("datatemp", dataTemp);
          context.commit("loadProjectMaturity", dataTemp);
          let responseMessage = null;
          if (data.getProjectMaturityLevel) {
            responseMessage = "Project Maturity created successfully!";
          } else {
            responseMessage = "Project Maturity updated successfully!";
          }

          if (!response.data.error) {
            let messageResponse = {
              error: false,
              message: responseMessage,
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
    async deleteProjectMaturity(context, data) {
      apolloClient
        .mutate({
          mutation: DeleteMaturity,
          variables: data,
        })
        .then((response) => {
          console.log("delete responce", response);
          if (response.data) {
            context.commit("deleteProjectMaturity", data);
            let messageResponse = {
              error: false,
              message: "Project Maturity deleted successfully!",
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
  },
};
