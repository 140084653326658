import { apolloClient } from "@/vue-apollo";
import DeleteStatus from "@/GraphQL/ProjectStatus/DeleteStatus.gql";
import allStatus from "@/GraphQL/ProjectStatus/allStatus.gql";
// eslint-disable-next-line no-unused-vars
import CreateProjectStatus from "@/GraphQL/ProjectStatus/CreateProjectStatus.gql";

export default {
  state: {
    projectStatus: [],
  },
  getters: {
    getProjectStatus: (state) => state.projectStatus,
  },
  mutations: {
    loadProjectStatus(state, projectStatusDetails) {
      if (
        state.projectStatus.find(
          ({ projectStatusUniqueId }) =>
            projectStatusUniqueId === projectStatusDetails.projectStatusUniqueId
        )
      ) {
        state.projectStatus.find(
          ({ projectStatusUniqueId }) =>
            projectStatusUniqueId === projectStatusDetails.projectStatusUniqueId
        ).projectStatusName = projectStatusDetails.projectStatusName;
        state.projectStatus.find(
          ({ projectStatusUniqueId }) =>
            projectStatusUniqueId === projectStatusDetails.projectStatusUniqueId
        ).creationDate = projectStatusDetails.creationDate;
      } else {
        state.projectStatus.push(projectStatusDetails);
      }
    },
    clearProjectStatus(state) {
      state.projectStatus = [];
    },
    deleteProjectStatus(state, data) {
      state.projectStatus.splice(
        state.projectStatus.findIndex(
          ({ projectStatusUniqueId }) =>
            projectStatusUniqueId === data.projectStatusUniqueId
        ),
        1
      );
    },
  },
  actions: {
    async loadProjectStatus(context) {
      context.commit("clearProjectStatus");
      context.commit("setLoadingState", true);
      apolloClient
        .query({
          query: allStatus,
        })
        .then((response) => {
          response.data.getProjectStatus?.forEach((element) => {
            let data = {
              projectStatusUniqueId: element.projectStatusUniqueId,
              projectStatusName: element.projectStatusName,
            };
            context.commit("loadProjectStatus", data);
          });
        })
        .finally(() => {
          context.commit("setLoadingState", false);
        });
    },
    async createProjectStatus(context, data) {
      apolloClient
        .mutate({
          mutation: CreateProjectStatus,
          variables: data,
        })
        .then((response) => {
          console.log("created", response.data.createProjectStatus.data);
          let dataTemp = {
            projectStatusUniqueId:response.data.createProjectStatus.data.projectStatusUniqueId,
            projectStatusName:response.data.createProjectStatus.data.projectStatusName,
          };

          console.log("datatemp", dataTemp);
          context.commit("loadProjectStatus", dataTemp);
          let responseMessage = null;
          if (data.createProjectStatus) {
            responseMessage = "Project Status created successfully!";
          } else {
            responseMessage = "Project Status updated successfully!";
          }

          if (!response.data.error) {
            let messageResponse = {
              error: false,
              message: responseMessage,
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
    async deleteProjectStatus(context, data) {
      apolloClient
        .mutate({
          mutation: DeleteStatus,
          variables: data,
        })
        .then((response) => {
          console.log("delete responce", response);
          if (response.data) {
            context.commit("deleteProjectStatus", data);
            let messageResponse = {
              error: false,
              message: "Project Category deleted successfully!",
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
  },
};
