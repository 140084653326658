<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    permanent
    app
    dark
    class="side-nav-style"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="@/assets/images/bibinabwana.png"></v-img>
      </v-list-item-avatar>

      <v-list-item-title class="ListItemClass">WERIS</v-list-item-title>

      <v-btn icon @click.stop="mini = !mini">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <span v-for="item in items" :key="item.title">
        <span v-if="
            ['User Management', 'Role Management', 'Manager Summary','Project Categories'].includes(
              item.title
            )
          ">
          <v-list-item
            router
            :to="item.route"
            link
            v-if="$store.getters.getUserState.isStaff"
          >
          <v-list-item-icon>
            <v-icon color="blue-grey lighten-5">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="ListItemClass">{{
                item.title
              }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </span>

        <v-list-item
          router
          :to="item.route"
          link
          v-else
        >
          <v-list-item-icon>
            <v-icon color="blue-grey lighten-5">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="ListItemClass">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </span>
    </v-list>
    <v-spacer></v-spacer>
  </v-navigation-drawer>
</template>

<script>
export default {
  props: ["mini"],
  data() {
    return {
      drawer: false,
      fav: true,
      menu: false,
      message: false,
      hints: true,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "/project/dashboard",
        },
        {
          title: "Projects",
          icon: "mdi-checkbox-marked-circle-plus-outline",
          route: "/project/projects",
        },
        {
          title: "Project Categories",
          icon: "mdi-format-list-checks",
          route: "/project/category",
        },
        {
          title: "Project Status",
          icon: "mdi-list-status",
          route: "/project/status",
        },
        {
          title: "Maturity Level",
          icon: "mdi-waves-arrow-up",
          route: "/project/maturity-level",
        },
        {
          title: "Project Report",
          icon: "mdi-chart-line",
          route: "/project/project_report_export",
        },
        {
          title: "Manager Summary",
          icon: "mdi-square",
          route: "/project/manager_summary_report",
          meta: {
            // permissions: ["Can view  project"],
          },
        },
        {
          title: "User Management",
          icon: "mdi-account-cog",
          route: { name: "Users" },
        },
        {
          title: "Role Management",
          icon: "mdi-account-lock",
          route: { name: "Roles" },
        },
      ],
    };
  },
  computed: {
    username() {
      return localStorage.getItem("loggedusername");
    },
  },
};
</script>
<style scoped>
.side-nav-style{
  background: #0c2e4c;/* fallback */
  background: linear-gradient(rgba(12, 46, 76,0.9), rgba(0, 115, 182, 0.6)),url("../../../../assets/images/mt_kilimanjaro.jpeg");
  background-size: cover;
  -webkit-transition:background-position 2s ease;
	-moz-transition:background-position 2s ease;
	-o-transition:background-position 2s ease;
	transition:background-position 2s ease;
}
.ListItemClass {
  color: #f5f5f5;
}
.SelectedTile:hover {
  border-radius: 4px;
  background: #455a64;
}
.SelectedTile:active {
  border-radius: 4px;
  background: rgba(10, 204, 117, 0.19);
}
</style>
