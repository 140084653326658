<template>
  <v-list-item class="todo">
    <div class="todo_title">
		{{ title }}
    </div>
    <v-spacer></v-spacer>
    <v-btn color="error" icon small
		@click="$emit('deleteWorkdone', id)"
		class="todo_action" >
		<v-icon small>mdi-trash-can</v-icon>
    </v-btn>
  </v-list-item>
</template>

<script>
export default {
  name: "Todo",
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      require: true,
    },
   
  },
 
};
</script>

<style lang="scss">
.todo {
  display: flex;
  justify-content: space-between;
  &__title--done {
    text-decoration: line-through;
  }
}
</style>
