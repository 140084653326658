<template>
  <v-app>
    <v-container fluid>
      <v-layout row wrap>
        <v-row class="pa-2">
          <v-col sm="6" md="4">
            
            <v-card link to="/project/projects" elevation="0">
            <v-alert
              border="left"
              colored-border
              color="#005AEE"
              elevation="2"
              prominent
              icon="mdi-folder"
            >
              <div class="d-flex flex-column justify-content-center">
                <h1>
                  {{
                    countAllProjectsPerUser
                  }}
                </h1>
                <span>Total Projects</span>
              </div>
            </v-alert>
            </v-card>
          </v-col>
          <v-col sm="6" md="4" v-for="(statistic,index) in dashboardStats" :key="index">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-card link :to="'/project/projects/'+statistic.name" elevation="0">
              <v-alert
                border="left"
                colored-border
                :color="genColor()"
                :elevation="hover ? 16 : 2"
                :class="{ 'on-hover': hover }"
                link
                to="/project/project_onProgress_per_user_table_data"
                prominent
                :icon="
                  statistic.name.toLowerCase().includes('production')
                    ? 'mdi-motion'
                    : statistic.name.toLowerCase().includes('development')
                    ? 'mdi-wrench'
                    : statistic.name.toLowerCase().includes('hold')
                    ? 'mdi-pause'
                    : statistic.name.toLowerCase().includes('search')
                    ? 'mdi-magnify'
                    : 'mdi-square'
                "
              >
                <div class="d-flex flex-column justify-content-center">
                  <h1>{{ statistic.value }}</h1>
                  <span>Projects {{ statistic.name }}</span>
                </div>
              </v-alert>
              </v-card>
            </v-hover>
          </v-col>

        </v-row>
      </v-layout>
      <br /><br />
      <v-divider> </v-divider>
      <br />
<!--      <v-layout row wrap>-->
<!--        <v-flex xs12 md6>-->
<!--          <div>-->
<!--            <v-card class="text-xs-center ma-3" max-width="500">-->
<!--              <apexcharts-->
<!--                width="500"-->
<!--                height="350"-->
<!--                type="bar"-->
<!--                :options="chartOptions"-->
<!--                :series="series"-->
<!--              ></apexcharts>-->
<!--            </v-card>-->
<!--          </div>-->
<!--        </v-flex>-->
<!--        <v-flex xs12 md6>-->
<!--          <div>-->
<!--            <v-card class="text-xs-center ma-3" max-width="500">-->
<!--              <apexcharts-->
<!--                type="polarArea"-->
<!--                :options="chartOptions1"-->
<!--                :series="series1"-->
<!--              ></apexcharts>-->
<!--            </v-card>-->
<!--          </div>-->
<!--        </v-flex>-->
<!--      </v-layout>-->
    </v-container>
  </v-app>
</template>

<script>
import countAllOnHoldProjectsPerUser from "../../../GraphQL/Projects/countOnHoldProjectsPerUser.gql";
// import VueApexCharts from "vue-apexcharts";
import countAllDelayedProjectsPerUser from "../../../GraphQL/Projects/Count/countAllDelayedProjectsPerUser/countAllDelayedProjectsPerUser.gql";
import countAllProjectsPerUser from "../../../GraphQL/Projects/countAllProjectsPerUser.gql";
import countAllCompletedProjectsPerUser from "../../../GraphQL/Projects/countAllCompletedProjectsPerUser.gql";
import getDashboardStatistics from "../../../GraphQL/Projects/getDashboardStatistics.graphql";
const COLORS = [
  'info',
  'warning',
  'error',
  'success',
]
export default {
  apollo: {
    countAllDelayedProjectsPerUser: {
      query: countAllDelayedProjectsPerUser,
      update: (data) => data.countAllDelayedProjectsPerUser,
      variables() {
        return {
          username: this.username,
        };
      },
    },
    countAllProjectsPerUser: {
      query: countAllProjectsPerUser,
      update: (data) => data.countAllProjectsPerUser,
      variables() {
        return {
          username: this.username,
        };
      },
    },
    countAllCompletedProjectsPerUser: {
      query: countAllCompletedProjectsPerUser,
      update: (data) => data.countAllCompletedProjectsPerUser,
      variables() {
        return {
          username: this.username,
        };
      },
    },
    countAllOnHoldProjectsPerUser: {
      query: countAllOnHoldProjectsPerUser,
      update: (data) => data.countAllOnHoldProjectsPerUser,
      variables() {
        return {
          username: this.username,
        };
      },
    },
    getDashboardStatistics: {
      query: getDashboardStatistics,
      update: (data) => data.getDashboardStatistics,
    },
  },
  components: {
    // apexcharts: VueApexCharts,
  },
  data: function () {
    return {
      chartOptions: {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          categories: [
            "In Production",
            "In Development",
            "On Hold",
            "Research",
            "Other",
          ],
        },
      },
      series: [
        {
          name: "series-1",
          data: [10, 50, 49, 60, 91],
        },
      ],
      series1: [21, 17, 15, 10, 30],
      chartOptions1: {
        chart: {
          type: "polarArea",
        },
        stroke: {
          colors: ["#fff"],
        },
        fill: {
          opacity: 0.8,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    username() {
      return localStorage.getItem("loggedusername");
    },
    dashboardStats(){
      let temp=[]
      this.getDashboardStatistics?.forEach((element) => {
        temp.push(JSON.parse(element.replaceAll("'", '"')));
      })
      return temp
    }
  },
  methods:{
    genColor () {
      return COLORS[Math.floor(Math.random() * 3)]
    },
  }
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
