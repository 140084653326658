var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-row',{staticClass:"pa-2"},[_c('v-col',{attrs:{"sm":"6","md":"4"}},[_c('v-card',{attrs:{"link":"","to":"/project/projects","elevation":"0"}},[_c('v-alert',{attrs:{"border":"left","colored-border":"","color":"#005AEE","elevation":"2","prominent":"","icon":"mdi-folder"}},[_c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('h1',[_vm._v(" "+_vm._s(_vm.countAllProjectsPerUser)+" ")]),_c('span',[_vm._v("Total Projects")])])])],1)],1),_vm._l((_vm.dashboardStats),function(statistic,index){return _c('v-col',{key:index,attrs:{"sm":"6","md":"4"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{attrs:{"link":"","to":'/project/projects/'+statistic.name,"elevation":"0"}},[_c('v-alert',{class:{ 'on-hover': hover },attrs:{"border":"left","colored-border":"","color":_vm.genColor(),"elevation":hover ? 16 : 2,"link":"","to":"/project/project_onProgress_per_user_table_data","prominent":"","icon":statistic.name.toLowerCase().includes('production')
                    ? 'mdi-motion'
                    : statistic.name.toLowerCase().includes('development')
                    ? 'mdi-wrench'
                    : statistic.name.toLowerCase().includes('hold')
                    ? 'mdi-pause'
                    : statistic.name.toLowerCase().includes('search')
                    ? 'mdi-magnify'
                    : 'mdi-square'}},[_c('div',{staticClass:"d-flex flex-column justify-content-center"},[_c('h1',[_vm._v(_vm._s(statistic.value))]),_c('span',[_vm._v("Projects "+_vm._s(statistic.name))])])])],1)]}}],null,true)})],1)})],2)],1),_c('br'),_c('br'),_c('v-divider'),_c('br')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }