<script>
// import managerSummaryReport from "../../../GraphQL/Projects/managerSummaryReport.GraphQL";
import allProjects from "../../../GraphQL/Projects/allProjects.gql";
// import { ref } from "vue";
// import jszip from 'jszip';
// import JSZipUtils from 'jszip-utils.js';
// import { saveAs } from 'file-saver';

export default {
  components: {
    // jszip,
    // JSZipUtils,
    // saveAs
  },
  apollo: {
    allProjects: {
      query: allProjects,
      update: (data) => data.allProjects,
    },
  },
  data() {
    return {
      search: "",
      start_date: "",
      allProjects: [],
      headers: [
        { text: "Sn", align: "start", sortable: false, value: "SN" },
        {
          text: "Project Activity",
          value: "projectName",
          sortable: false,
        },
        {
          text: "Actions Taken During the Week",
          value: "thisWeek",
          sortable: false,
        },
        {
          text: "Plan for  Next the Week",
          value: "nextWeek",
          sortable: false,
        },
        {
          text: "Project Blocking Issue",
          value: "blockingissue",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    //export Manager  Report
    export_to_word() {
      // const printContainer = ref();
      // console.log("inside the fnction", printContainer.value.innerHTML);
    },
    getDoc() {
      this.createAndSaveDocument();
    },
    loadFile() {
      // this.JSzipUtils.getBinaryContent(url, callback);
    },
  },
  computed: {
    filteredProjects() {
      return this.start_date == ""
        ? this.projects
        : this.projects.filter((p) => p.start_date == this.start_date);
    },
  },
};
</script>

<template>
  <div ref="printContainer">
    <v-card class="pa-2" outlined>
      <v-card-title>
        <h4 class="grey--text">Manager Summary</h4>
        <v-layout row wrap>
          <v-row>
            <v-col cols="12" sm="6" md="3"> </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="6" md="3">
              <v-btn
                outlined
                class="text-none mt-2"
                color="primary"
                @click="export_to_word"
              >
                <v-icon class="mr-2"> mdi-microsoft-word </v-icon>
                Export to Word</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-layout>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="allProjects"
        :search="search"
        class="elevation-10"
      >
        <template v-slot:item.SN="{ item }">
          {{ allProjects.indexOf(item) + 1 }}
        </template>

        <template v-slot:item.thisWeek="{ item }">
          <ul>
            <span v-for="desc in item.taskProject" :key="desc">
              <li v-if="!desc.isNextWeekTask" style="list-style: circle">
                {{ desc.taskDescription }}
              </li>
            </span>
          </ul>
        </template>
        <template v-slot:item.nextWeek="{ item }">
          <span v-for="desc in item.taskProject" :key="desc">
            <li v-if="desc.isNextWeekTask" style="list-style: circle">
              {{ desc.taskDescription }}
            </li>
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
