<template>
  <v-container>
    
    <v-card class="rounded-lg pa-2 pt-2 pb-2" elevation="0" color="#efefef">
    <v-card-title>Next week tasks:</v-card-title>
    <v-card-text>
      <v-list style="background: transparent">
        <span
          v-for="singleTask in $store.getters.getTasks"
          :key="singleTask.taskId"
          style="background: transparent; padding: 0"
        >
          <v-list-item v-if="singleTask.isNextWeekTask">
            <v-list-item-content class="pa-0 ma-0">
              <div
                class="d-flex justify-space-between align-center px-4 mb-1 py-1 rounded-lg"
                style="background: #ddd; min-height: 40px"
              >
                <v-list-item-action-text
                  style="width: 100%"
                  tag="div"
                  :contenteditable="singleTask.isDeletableOrEditable"
                  :no-nl="true"
                  :no-html="true"
                  @input="singleTask.taskDescription = $event.target.innerText"
                  v-model="singleTask.taskDescription"
                  @blur="
                    singleTask.taskDescription = $event.target.innerText;
                    updateTask(singleTask);
                  "
                  >{{ singleTask.taskDescription }}</v-list-item-action-text
                >
                <v-btn
                  text
                  dense
                  fab
                  small
                  @click="deleteTask(singleTask.taskId)"
                  v-if="singleTask.isDeletableOrEditable"
                  ><v-icon small color="red">mdi-delete</v-icon></v-btn
                >
              </div>
            </v-list-item-content>
          </v-list-item>
        </span>
      </v-list>
    </v-card-text>
    <v-card-actions
      ><v-text-field
        class="input rounded-lg"
        label="Next week activity"
        hint="Enter to submit"
        append-icon="mdi-plus-circle"
        multiple
        @keydown.enter="submitWorkdone"
        @click:append="submitWorkdone"
        v-model="workdone"
        outlined
      >
      </v-text-field
    ></v-card-actions>
  </v-card>
  <v-card class="rounded-lg pa-2 pt-2 pb-2 mt-3" elevation="0" color="#efefef">
     <v-subheader v-if="$store.getters.getProjectWeeklySummary.find(({ isNextWeek }) => isNextWeek === true)">Next week activity Summary:</v-subheader>
    <v-card-text class="ma-0 pa-0">
   <span
          v-for="singleSummary in $store.getters.getProjectWeeklySummary"
          :key="singleSummary.summaryId"
          style="background: transparent; padding: 0"
        >
          <v-list-item v-if="singleSummary.isNextWeek">
            <v-list-item-content class="pa-0 ma-0">
              <div
                class="d-flex justify-space-between align-center px-4 mb-1 py-1 rounded-lg"
                style="background: #ddd; min-height: 40px"
              >
                
                <v-list-item-action-text
                  style="width: 100%"
                  tag="div"
                  :contenteditable="singleSummary.isDeletableOrEditable"
                  :no-nl="true"
                  :no-html="true"
                  @input="singleSummary.description = $event.target.innerText"
                  v-model="singleSummary.description"
                  @blur="
                    singleSummary.description = $event.target.innerText;
                    updateSummary(singleSummary);
                  "
                  >{{ singleSummary.description }}</v-list-item-action-text
                >
                <v-btn
                  text
                  dense
                  @click="deleteSummary(singleSummary.summaryId)"
                  v-if="singleSummary.isDeletableOrEditable"
                  ><v-icon small color="red">mdi-delete</v-icon></v-btn
                >
              </div>
            </v-list-item-content>
          </v-list-item>
        </span>
        </v-card-text>
        <v-card-actions v-if="!$store.getters.getProjectWeeklySummary.find(({ isNextWeek }) => isNextWeek === true)"
      >
    <v-text-field
        class="input rounded-lg mt-4"
        append-icon="mdi-plus-circle"
        multiple
        @keydown.enter="submitWeeklySummary"
        @click:append="submitWeeklySummary"
        v-model="thisWeekSummary"
        outlined
        label="Next week activity summary"
        hint="One sentence summary of all next week activities"
      >
      </v-text-field>
        </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Way_Forward_Todo",
  data: () => ({
    workdone: "",
    thisWeekSummary:"",
    // taskList: [],
  }),
  mounted() {
    // this.$store.dispatch("loadTasks", this.$route.params.projectId);
  },
  methods: {
    deleteTask(taskId) {
      this.$store.dispatch("deleteTasks", { taskId: taskId });
    },
    submitWorkdone() {
      if (this.workdone !== "") {
        let data = {
          taskId: null,
          taskDescription: this.workdone,
          isDeletableOrEditable: true,
          isNextWeekTask: true,
          projectId: this.$route.params.projectId,
          taskStatus: false,
        };
        this.$store.dispatch("createUpdateTask", data);
      }
      this.workdone = "";
    },
    updateTask(taskDetails) {
      // let data = {
      //   taskId: taskDetails.taskId,
      //   taskDescription: taskDetails.taskDescription,
      //   isDeletableOrEditable: taskDetails.isDeletableOrEditable,
      //   isNextWeekTask: taskDetails.isNextWeekTask,
      //   projectId: taskDetails.projectId,
      //   taskStatus: taskDetails.taskStatus,
      // };
      // console.log("Updating Task",);
      this.$store.dispatch("createUpdateTask", taskDetails);
    },
    contentEditableCheck(event, taskDescription) {
      console.log(taskDescription);
    },
     deleteSummary(summaryId) {
      this.$store.dispatch("deleteWeeklySummary", { summaryId: summaryId });
    },
    submitWeeklySummary() {
      if (this.thisWeekSummary !== "") {
        let data = {
          summaryId: null,
          description: this.thisWeekSummary,
          isDeletableOrEditable: true,
          isNextWeek: true,
          projectId: this.$route.params.projectId,
        };
        this.$store.dispatch("createUpdateWeeklySummary", data);
      }
      this.thisWeekSummary = "";
    },
    updateSummary(itemDetails) {
      this.$store.dispatch("createUpdateWeeklySummary", itemDetails);
    },
  },
};
</script>

<style>
.input {
  width: 100%;
}
</style>
