<template>
    <div class="main-container">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12">
            <div class="d-flex justify-space-between mb-4">
              <v-btn
                to="/project/status"
                color="primary"
                class="text-capitalize rounded-lg"
              >
                <v-icon small>mdi-arrow-left-circle</v-icon>
                Status
              </v-btn>
              <v-spacer></v-spacer>
              <span>
                <v-btn
                  small
                  class="mx-2"
                  fab
                  color="primary"
                  dark
                  dense
                  @click="createUpdateProjectMaturityDialog = true"
                >
                  <v-icon small> mdi-plus</v-icon>
                </v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <CreateProjectMaturity
                      v-bind="attrs"
                      v-on="on"
                      :CreateUpdateProjectMaturityDialog="
                        createUpdateProjectMaturityDialog
                      "
                      :CreateUpdateProjectMaturityData="
                        createUpdateProjectMaturityData
                      "
                      @close="createUpdateProjectMaturityDialog = false"
                    >
                    </CreateProjectMaturity>
                  </template>
                  <span>Create Project Maturity Level</span>
                </v-tooltip>
              </span>
            </div>
            <h3 class="">Project Maturity Level</h3>
          </v-col>
        </v-row>
        <v-row> </v-row>
        <br />
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <!-- {{$store.getters.getProjectMaturityLevel}} -->
              <v-data-table
                :headers="headers"
                :items="$store.getters.getProjectMaturityLevel"
                :search="search"
                class="elevation-10"
              >
                <template v-slot:item.SN="{ item, index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.creationDate="{ item }">
                  {{ item.creationDate | formatDate }}
                </template>
                <template v-slot:item.action="{ item }">
                  <v-menu dense offset-x :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        icon
                        v-bind="attrs"
                        v-on="on"
                        right
                        :nudge-width="200"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="UpdateProjectMaturity(item)">
                        <v-list-item-icon>
                          <v-icon small>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title> Edit Maturity</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="DeleteProjectMaturity(item.projectMaturityLevelUniqueId)">
                        <v-list-item-icon>
                          <v-icon color="#f83e70" left small>mdi-delete</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Delete Maturity</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  import CreateProjectMaturity from "../maturityLevel/CreateProjectMaturityLevel.vue";
  export default {
    data() {
      return {
        dialog: false,
        createUpdateProjectMaturityDialog: false,
        createUpdateProjectMaturityData: null,
        categoryNameRules: [
          (v) => !!v || "ProjectCategories Name is required",
          (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
        ],
        category: {},
        allCategories: [],
        chips: "",
        search: "",
        items: [{ icon: "mdi-eye", title: "Reports", route: "/project_report" }],
        headers: [
          { text: "Sn", align: "start", sortable: false, value: "SN" },
          {
            text: "ProjectCategories Name",
            value: "projectMaturityLevelName",
            sortable: false,
          },
          
          {
            text: "Action",
            value: "action",
            sortable: false,
          },
        ],
      };
    },
    methods: {
      sortBy(prop) {
        this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
      },
      DeleteProjectMaturity(projectMaturityLevelUniqueId) {
        let data = {
          projectMaturityLevelUniqueId: projectMaturityLevelUniqueId,
        };
        this.$store.dispatch("deleteProjectMaturity", data);
      },
      editCategory(cat) {
        this.category = cat;
      },
      UpdateProjectMaturity(item) {
        this.createUpdateProjectMaturityDialog = true;
        this.createUpdateProjectMaturityData = item;
      },
    },
    components: { CreateProjectMaturity },
    computed: {
      username() {
        return localStorage.getItem("loggedusername");
      },
    },
    mounted() {
      this.$store.dispatch("loadProjectMaturity");
    },
  };
  </script>
  
  <style>
  .project.completed {
    border-left: 4px solid #3cd1c2;
  }
  .project.ongoing {
    border-left: 4px solid orange;
  }
  .project.overdue {
    border-left: 4px solid tomato;
  }
  #chip.v-chip.completed {
    background: #3cd1c2;
  }
  #chip.v-chip.ongoing {
    background: #ffaa2c;
  }
  #chip.v-chip.overdue {
    background: #f83e70;
  }
  .no-uppercase {
    text-transform: unset !important;
  }
  .main-container {
    padding: 30px 50px;
    height: 100%;
    width: 100%;
    background: #fff;
  }
  .w-100 {
    width: 100% !important;
  }
  </style>
  