<template>
	<v-app>
		<ToolBar />
		<v-card class="pa-10">
			<h2>Projects Details</h2>
		<v-card-title>
			<v-layout row wrap>
				<v-row>
					   <v-col cols="12" sm="6" md="3">
							<v-menu
							v-model="menu"
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto"
							>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
								v-model="date"
								label="Start Date"
								prepend-icon="mdi-calendar-end"
								readonly
								v-bind="attrs"
								v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="start_date"
								@input="menu = false"
							></v-date-picker>
							</v-menu>
                    </v-col>
					<v-col cols="12" sm="6" md="3">
							<v-menu
							v-model="menu1"
							:close-on-content-click="false"
							:nudge-right="40"
							transition="scale-transition"
							offset-y
							min-width="auto"
							>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
								v-model="date1"
								label="Completion Date"
								prepend-icon="mdi-calendar-end"
								readonly
								v-bind="attrs"
								v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="date1"
								@input="menu1 = false"
							></v-date-picker>
							</v-menu>
              		</v-col>
					<v-col cols="12" sm="6" md="3">
					   <div>
						 <v-btn 
							icon 
							color="green" 
							@click="export_data_to_exel">
							<v-icon v-html="'mdi-download-circle'" /> 
							<span class="text-center text-none">Export</span>
						 </v-btn>
						</div>
					 </v-col>
					<v-col cols="12" sm="6" md="3">
						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Search"
							single-line
							hide-details>
						</v-text-field>
					</v-col>
				</v-row>
			</v-layout>
		</v-card-title>
		<v-data-table 
			:headers="headers"
			:items="allProjects"
			:search="search">
			<template v-slot:item.SN="{ item }">
				{{ allProjects.indexOf(item) + 1 }} 
			</template>
			<template v-slot:item.taskProject="{item}">{{
				item.taskProject.map(function(taskDes) { return taskDes.taskDescription }).toString()
			}}</template>
		</v-data-table>
		
		</v-card>
	</v-app>
</template>

<script>
import ToolBar from "../weris_shared/navigations/ToolBar.vue";
import allProjects from '../../../GraphQL/Projects/allProjects.gql'
import { jsontoexcel } from "vue-table-to-excel";
export default {
  components: {
    ToolBar,
  },
  apollo: {
    allProjects:{
      query:allProjects,
      update:(data) =>data.allProjects
    },
	
  },
  data() {
    return {
      search: "",
		start_date : "",
      headers: [
        { text: "Sn", align: "start", sortable: false, value: "SN" },
        { text: "Project Activity", value: "projectName", sortable: false },
        { text: "Client/Institution", value: "projectClient", sortable: false },
        { text: "Description", value: "projectDescription", sortable: false },
        { text: "Project Leader", value: "projectLeader.username", sortable: false },
        { text: "Status", value: "projectStatus", sortable: true },
        { text: "Start Date", value: "projectStartDate", sortable: false },
        { text: "Completion Date", value: "projectEndDate", sortable: false },
        {
          text: "Actions Taken During the Week",
          value: "taskProject",
          sortable: false,
        },
		{
          text: "Plan for the Week",
          value: "taskProject",
          sortable: false,
        },
		{ text: "Project Comments", value: "projectComments", sortable: false },
      ],
      heads: [
		"Sn",
        "Project Activity",
        "Client/Institution",
        "Description",
        "Project Team",
        "Status",
        "Project Comments",
        "Start Date",
        "Expected Completion Date",
      ],
      allProjects: [
     
      ],
	//    date:new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
       date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
	    menu: false,
		modal: false,
		menu1: false,
		menu2: false,
    };
  },
  methods: {
    export_data_to_exel() {
      jsontoexcel.getXlsx(this.allProjects, this.heads, "projects_report.xls");
    },
  },
  computed : {
	filteredProjects() {
		return this.start_date == "" ? this.projects : this.projects.filter( (p) => (p.start_date == this.start_date))
	}
  }
};
</script>

<style>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
}

.card {
  all: unset;
  border-radius: 20px;
  border: 1px solid #eee;
  background-color: #fafafa;
  height: 40px;
  width: 200px;
  margin: 0 8px 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  line-height: 24px;
}

.card-container .card:not(:last-child) {
  margin-right: 0;
}

.card.card-small {
  height: 16px;
  width: 168px;
}

.card-container .card:not(.highlight-card) {
  cursor: pointer;
}

.card-container .card:not(.highlight-card):hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 17px rgba(0, 0, 0, 0.35);
}

.card-container .card:not(.highlight-card):hover .material-icons path {
  fill: rgb(105, 103, 103);
}

.card.highlight-card {
  background-color: #1976d2;
  color: white;
  font-weight: 600;
  border: none;
  width: auto;
  min-width: 30%;
  position: relative;
}

.card.card.highlight-card span {
  margin-left: 60px;
}
</style>

