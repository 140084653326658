<template>
<v-container>
  
  <v-card class="rounded-lg pa-2 pt-2 pb-2" elevation="0" color="#efefef">
    <v-card-title>This week tasks:</v-card-title>
    <v-card-text>
      <v-list style="background: transparent" dense>
        <span
          v-for="singleTask in $store.getters.getTasks"
          :key="singleTask.taskId"
          style="background: transparent; padding: 0"
        >
          <v-list-item v-if="!singleTask.isNextWeekTask" >
            <v-list-item-content class="pa-0 ma-0">
              <div
                class="d-flex justify-space-between align-center px-4 mb-1 py-1 rounded-lg"
                style="background: #ddd; min-height: 40px"
              >
                <v-checkbox
                  v-model="singleTask.taskStatus"
                  class="mt-3"
                  @change="updateTask(singleTask)"
                  dense
                ></v-checkbox>
                <v-subheader
                  style="width: 100%;"
                  tag="div"
                  :contenteditable="singleTask.isDeletableOrEditable"
                  :no-nl="true"
                  :no-html="true"
                  @input="singleTask.taskDescription = $event.target.innerText"
                  v-model="singleTask.taskDescription"
                  @blur="
                    singleTask.taskDescription = $event.target.innerText;
                    updateTask(singleTask);
                  "
                  >{{ singleTask.taskDescription }}</v-subheader
                >
                <v-btn
                  text
                  fab
                  small
                  dense
                  @click="deleteTask(singleTask.taskId)"
                  ><v-icon small color="red">mdi-delete</v-icon></v-btn
                >
              </div>
            </v-list-item-content>
          </v-list-item>
        </span>
      </v-list>
    </v-card-text>
    <v-card-actions
      ><v-text-field
        class="input rounded-lg"
        label="This week activity"
        hint="Enter to submit"
        append-icon="mdi-plus-circle"
        multiple
        @keydown.enter="submitWorkdone"
        @click:append="submitWorkdone"
        v-model="workdone"
        outlined
        
      >
      </v-text-field
    >
   
    </v-card-actions>
  </v-card>
  <v-card class="rounded-lg px-2 pb-2 mt-3" elevation="0" color="#efefef">
     <v-subheader class="pb-0 mb-0" v-if="$store.getters.getProjectWeeklySummary.find(({ isNextWeek }) => isNextWeek === false)">This week activity Summary:</v-subheader>
    <v-card-text class="ma-0 pa-0">
      <span
        v-for="singleSummary in $store.getters.getProjectWeeklySummary"
        :key="singleSummary.summaryId"
        style="background: transparent; padding: 0"
      >
        <v-list-item v-if="!singleSummary.isNextWeek">
          <v-list-item-content class="pa-0 ma-0">
            <div
              class="d-flex justify-space-between align-center px-4 mb-1 py-1 rounded-lg"
              style="background: #ddd; min-height: 40px"
            >
              
              <v-list-item-action-text
                style="width: 100%"
                tag="div"
                :contenteditable="true"
                :no-nl="true"
                :no-html="true"
                @input="singleSummary.description = $event.target.innerText"
                v-model="singleSummary.description"
                @blur="
                  singleSummary.description = $event.target.innerText;
                  updateSummary(singleSummary);
                "
                >{{ singleSummary.description }}</v-list-item-action-text
              >
              <v-btn
                text
                dense
                fab
                small
                @click="deleteSummary(singleSummary.summaryId)"
                ><v-icon small color="red">mdi-delete</v-icon></v-btn
              >
            </div>
          </v-list-item-content>
        </v-list-item>
      </span>
    </v-card-text>
    <v-card-actions v-if="!$store.getters.getProjectWeeklySummary.find(({ isNextWeek }) => isNextWeek === false)">
      <v-text-field
          class="input rounded-lg mt-5"
          append-icon="mdi-plus-circle"
          multiple
          @keydown.enter="submitWeeklySummary"
          @click:append="submitWeeklySummary"
          v-model="thisWeekSummary"
          label="This week activity summary"
          hint="One sentence summary of all activities this week"
          outlined
        >
        </v-text-field>
    </v-card-actions>
  </v-card>

<!-- start blockissues  -->
<v-card class="rounded-lg px-2 pb-2 mt-3" elevation="0" color="#efefef">
     <v-subheader class="pb-0 mb-0" v-if="$store.getters.getProjectWeeklyBlockingIssues.find(({ isNextWeek }) => isNextWeek === false)">This week blockissues Summary</v-subheader>
    <v-card-text class="ma-0 pa-0">
      <span
        v-for="singleBlockingIssues in $store.getters.getProjectWeeklyBlockingIssues"
        :key="singleBlockingIssues.summaryId"
        style="background: transparent; padding: 0"
      >
        <v-list-item v-if="!singleBlockingIssues.isNextWeek">
          <v-list-item-content class="pa-0 ma-0">
            <div
              class="d-flex justify-space-between align-center px-4 mb-1 py-1 rounded-lg"
              style="background: #ddd; min-height: 40px"
            >
              
              <v-list-item-action-text
                style="width: 100%"
                tag="div"
                :contenteditable="true"
                :no-nl="true"
                :no-html="true"
                @input="singleBlockingIssues.description = $event.target.innerText"
                v-model="singleBlockingIssues.description"
                @blur="
                  singleBlockingIssues.description = $event.target.innerText;
                  updateSummary(singleBlockingIssues);
                "
                >{{ singleBlockingIssues.description }}</v-list-item-action-text
              >
              <v-btn
                text
                dense
                fab
                small
                @click="deleteBlockingIssues(singleBlockingIssues.blockingIssueId)"
                ><v-icon small color="red">mdi-delete</v-icon></v-btn
              >
            </div>
          </v-list-item-content>
        </v-list-item>
      </span>
    </v-card-text>
    <v-card-actions v-if="!$store.getters.getProjectWeeklyBlockingIssues.find(({ isNextWeek }) => isNextWeek === false)">
      <v-text-field
          class="input rounded-lg mt-5"
          append-icon="mdi-plus-circle"
          multiple
          @keydown.enter="submitWeeklyBlockingIssues"
          @click:append="submitWeeklyBlockingIssues"
          v-model="thisWeekBlockingIssues"
          label="Blocking Issues"
          hint="One sentence summary of all blocking issues in this week"
          outlined
        >
        </v-text-field>
    </v-card-actions>
</v-card>

<!-- end blocking issues -->
  
  </v-container>
  
</template>

<script>
export default {
  name: "Workdone_Todo",
  data: () => ({
    workdone: "",
    thisWeekSummary:"",
    thisWeekBlockingIssues:"",
    // taskList: [],
  }),
  mounted() {
    this.$store.dispatch("loadTasks", this.$route.params.projectId);
    this.$store.dispatch("loadWeeklySummaries", this.$route.params.projectId);
  },
  methods: {
    deleteTask(taskId) {
      this.$store.dispatch("deleteTasks", { taskId: taskId });
    },
    deleteSummary(summaryId) {
      this.$store.dispatch("deleteWeeklySummary", { summaryId: summaryId });
    },
    deleteBlockingIssues(blockingIssueId) {
      this.$store.dispatch("deleteBlockingIssues", { blockingIssueId: blockingIssueId });
    },
    submitWorkdone() {
      if (this.workdone !== "") {
        let data = {
          taskId: null,
          taskDescription: this.workdone,
          isDeletableOrEditable: true,
          isNextWeekTask: false,
          projectId: this.$route.params.projectId,
          taskStatus: false,
        };
        this.$store.dispatch("createUpdateTask", data);
      }
      this.workdone = "";
    },
    submitWeeklySummary() {
      if (this.thisWeekSummary !== "") {
        let data = {
          summaryId: null,
          description: this.thisWeekSummary,
          isDeletableOrEditable: true,
          isNextWeek: false,
          projectId: this.$route.params.projectId,
        };
        this.$store.dispatch("createUpdateWeeklySummary", data);
      }
      this.thisWeekSummary = "";
    },
    submitWeeklyBlockingIssues() {
      if (this.thisWeekBlockingIssues !== "") {
        let data = {
          blockingIssueId: null,
          description: this.thisWeekBlockingIssues,
          // isDeletableOrEditable: true,
          // isNextWeek: false,
          projectId: this.$route.params.projectId,
        };
        this.$store.dispatch("createUpdateWeeklyBlockingIssue", data);
      }
      this.thisWeekSummary = "";
    },
    updateTask(taskDetails) {
      this.$store.dispatch("createUpdateTask", taskDetails);
    },
    updateSummary(itemDetails) {
      this.$store.dispatch("createUpdateWeeklySummary", itemDetails);
    },
    updateBlockingIssues(itemDetails) {
      this.$store.dispatch("createUpdateWeeklyBlockingIssue", itemDetails);
    },
    contentEditableCheck(event, taskDescription) {
      console.log(taskDescription);
    },
    content(data){
      console.log(data);
      return data
    }
  },
};
</script>

<style>
.input {
  width: 100%;
}
</style>
