<template>
  <v-app>
    <v-container fluid class="main-container">
      <ToolBar />
      <div class="d-flex justify-space-between mb-4">
        <v-btn
          to="/project/projects"
          color="primary"
          class="text-capitalize rounded-lg"
          ><v-icon small>mdi-arrow-left-circle</v-icon> Projects</v-btn
        >
        <v-btn
          @click="
            $router.push({
              name: 'Project Detailed View',
              params: { projectId: $route.params.projectId },
            })
          "
          color="primary"
          class="text-capitalize rounded-lg"
          ><v-icon small>mdi-eye</v-icon> Project Details</v-btn
        >
      </div>
      <h2 class="">Reporting: {{ projectTitle }}</h2>
      <v-row>
        <div
          class="d-flex justify-space-between mx-10 mb-7 mt-7"
          style="width: 100%"
        ></div>
      </v-row>
      <v-card class="" outlined>
        <v-row class="pa-5">
          <v-col cols="12" sm="6" small outline center>
            <ThisWeekTaskInputField @workdone="(m) => addWorkDone(m)" />
            <v-list class="todo_list">
              <ThisWeekActivitiesList
                v-for="(todo, index) in workdone"
                @deleteWorkdone="(wId) => deleteWorkDone(wId)"
                :title="todo.message"
                :id="todo.id"
                :key="'thisWeekActivities' + index"
              ></ThisWeekActivitiesList>
            </v-list>
          </v-col>
          <v-col cols="12" sm="6">
            <NextWeekTaskInputField @wayForward="(wf) => addWayForward(wf)" />
            <v-list class="todo_list">
              <NextWeekActivitiesList
                v-for="(wf, index) in wayForward"
                @deleteWayForward="(wfId) => deleteWayForward(wfId)"
                :title="wf.message"
                :id="wf.id"
                :key="'nextWeekActivities' + index"
              ></NextWeekActivitiesList>
            </v-list>
          </v-col>

          <!-- <v-col cols="12" sm="6">
            <Blocking_Issues_Input @wayForward="(wf) => addWayForward(wf)" />
            <v-list class="todo_list">
              <Blocking_Issues_Todo
                v-for="(wf, index) in wayForward"
                @deleteWayForward="(wfId) => deleteWayForward(wfId)"
                :title="wf.message"
                :id="wf.id"
                :key="'nextWeekActivities' + index"
              ></Blocking_Issues_Todo>
            </v-list>
          </v-col> -->
          <v-col cols="12" md="12">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                v-for="item in $store.getters.getAttachments"
                :key="item.filePath"
                style="padding: 0"
              >
                <v-list-item dense>
                  <v-list-item-content class="pa-0 ma-0">
                    <div
                      class="d-flex justify-space-between align-center px-4 mb-1 py-1 rounded-lg"
                      style="background: #ddd; min-height: 40px"
                    >
                      <v-list-item-icon
                        ><img
                          :src="
                            $store.getters.getIcons[
                              item.fileExtension.split('.')[1]
                            ]
                              ? $store.getters.getIcons[
                                  item.fileExtension.split('.')[1]
                                ]
                              : $store.getters.getIcons['file']
                          "
                          height="30"
                      /></v-list-item-icon>
                      <v-list-item-action-text style="width: 100%">
                        {{ item.fileName }}<br /><span class="grey--text"
                          ><b>Created On:</b>
                          {{ item.createdDate.substr(0, 10) }}</span
                        ></v-list-item-action-text
                      >

                      <a
                        :href="backend_url + item.filePath"
                        style="text-decoration: none"
                        :download="item.fileName"
                        target="_blank"
                        ><v-icon small color="primary"
                          >mdi-download-box</v-icon
                        ></a
                      >
                      <v-btn
                        text
                        fab
                        small
                        @click="deleteAttachment(item.attachmentId)"
                        ><v-icon small color="error">mdi-delete</v-icon></v-btn
                      >
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-col>

        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import ToolBar from "../../Projects/weris_shared/navigations/ToolBar.vue";
// import Blocking_Issues_Input from "../Report_Main/Blocking_Issues/Blocking_Issues_Input.vue";
// import Blocking_Issues_Todo from "../Report_Main/Blocking_Issues/Blocking_Issues_Todo.vue";
import ThisWeekActivitiesList from "./Workdone_Activity/ThisWeekActivitiesList.vue";
import ThisWeekTaskInputField from "./Workdone_Activity/ThisWeekTaskInputField.vue";
import NextWeekTaskInputField from "./Way_Forward/NextWeekTaskInputField.vue";
import NextWeekActivitiesList from "./Way_Forward/NextWeekActivitiesList.vue";
import CreateMultipleTasks from "../../../GraphQL/Tasks/CreateMultipleTasks.gql";
import submitAttachment from "@/GraphQL/Tasks/submitAttachment.graphql";
export default {
  components: {
    ThisWeekTaskInputField,
    ThisWeekActivitiesList,
    NextWeekTaskInputField,
    NextWeekActivitiesList,
    // Blocking_Issues_Todo,
    // Blocking_Issues_Input,
    ToolBar,
  },
  props:['projectTitle','projectId'],
  data() {
    return {
      endDateMenu: false,
      startDateMenu: false,
      backend_url: process.env.VUE_APP_BACKEND_URL,
      projectStatusItems: ["OnProgress", "Completed"],
      workdone: [],
      wayForward: [],
      status: undefined,
      taskStartDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      taskEndDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dropzoneOptions: {
        url: process.env.VUE_APP_FILE_UPLOAD_API,
        thumbnailWidth: 150,
        maxFilesize: 20.5,
        // acceptedFiles:'image/*',
        paramName: "uploaded_files",
        // maxFiles: 1,
        autoProcessQueue: true,
        addRemoveLinks: true,
        dictDefaultMessage: "Click here to add attachment",
        headers: {
          Authorization: null,
          "Cache-Control": null,
          // "X-Requested-With": null,
        },
        success: (file, response) => {
          // console.log(file);
          // console.log(response);
          // console.log(this);
          this.$apollo
            .mutate({
              mutation: submitAttachment,
              variables: {
                fileName: response.file_name,
                filePath: response.file_path,
                fileSize: response.file_size,
                fileExtension: response.file_extension,
                projectId: this.$route.params.projectId,
              },
            })
            .then((response) => {
              let { createdAttachment, success } =
                response.data.submitAttachment;
              success
                ? this.$store.commit("loadAttachments", createdAttachment)
                : null;
            });
        },
      },
    };
  },
  mounted() {
   
    this.$store.dispatch(
      "loadProjectAttachments",
      this.$route.params.projectId
    );
  },
  methods: {
    addWorkDone(workdone) {
      const todoObj = {
        id: Math.random(),
        message: workdone,
      };
      // console.log("workdone From Reporting", workdone);

      this.workdone.push(todoObj);
    },
    addWayForward(wayForward) {
      const todoObj1 = {
        id: Math.random(),
        message: wayForward,
      };
      // console.log("Way From Reporting", wayForward);
      this.wayForward.push(todoObj1);
    },
    deleteAttachment(attachmentId) {
      // console.log(attachmentId);
      this.$store.dispatch("deleteAttachment", { attachmentId: attachmentId });
    },
    deleteWayForward(id) {
      const wfIdx = this.wayForward.findIndex((wf) => wf.id === id);
      this.wayForward.splice(wfIdx, 1);
    },
    deleteWorkDone(id) {
      const todoIndex = this.workdone.findIndex((wd) => wd.id === id);
      this.workdone.splice(todoIndex, 1);
    },

    //submit task
    submitTask() {
      let data = {
        workdoneTasks: this.workdone.map((wd) => ({
          taskDescription: wd.message,
        })),
        wayForwardTasks: this.wayForward.map((wf) => ({
          taskDescription: wf.message,
        })),
        // taskCompletionDate: this.taskEndDate,
        // taskStartDate: this.taskStartDate,
        userId: localStorage.getItem("loggedUserId"),
        projectId: this.$route.params.projectId,
      };
      // console.log(data);
      this.$apollo.mutate({
        mutation: CreateMultipleTasks,
        variables: data,
        update: (cache, response) => {
          // console.log(response.data);
          if (response.data.CreateMultipleTasks.success) {
            // console.log("SENT ", response.data.CreateMultipleTasks);
            // this.$apollo.queries.CreateMultipleTasks.refresh(); // refresh projects
            this.$swal.fire({
              position: "top-end",
              icon: "info",
              title: "Task Success Created",
              showConfirmButton: false,
              timer: 1500,
              height: "100px",
            });
          } else {
            // console.log("ERROR ", response.errors);
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: false,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: "Duplicate Key!",
            });
          }
          this.dialog = false;
        },
      });
    },
  },
  computed: {
    icons() {
      return this.$store.getters.getIcons;
    },
  },
};
</script>

<style>
.no-uppercase {
  text-transform: unset !important;
}
</style>
