var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-container',{staticClass:"main-container",attrs:{"fluid":""}},[_c('ToolBar'),_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"to":"/project/projects","color":"primary"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-left-circle")]),_vm._v(" Projects")],1),_c('v-btn',{staticClass:"text-capitalize rounded-lg",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({
            name: 'Project Detailed View',
            params: { projectId: _vm.$route.params.projectId },
          })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")]),_vm._v(" Project Details")],1)],1),_c('h2',{},[_vm._v("Reporting: "+_vm._s(_vm.projectTitle))]),_c('v-row',[_c('div',{staticClass:"d-flex justify-space-between mx-10 mb-7 mt-7",staticStyle:{"width":"100%"}})]),_c('v-card',{attrs:{"outlined":""}},[_c('v-row',{staticClass:"pa-5"},[_c('v-col',{attrs:{"cols":"12","sm":"6","small":"","outline":"","center":""}},[_c('ThisWeekTaskInputField',{on:{"workdone":(m) => _vm.addWorkDone(m)}}),_c('v-list',{staticClass:"todo_list"},_vm._l((_vm.workdone),function(todo,index){return _c('ThisWeekActivitiesList',{key:'thisWeekActivities' + index,attrs:{"title":todo.message,"id":todo.id},on:{"deleteWorkdone":(wId) => _vm.deleteWorkDone(wId)}})}),1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('NextWeekTaskInputField',{on:{"wayForward":(wf) => _vm.addWayForward(wf)}}),_c('v-list',{staticClass:"todo_list"},_vm._l((_vm.wayForward),function(wf,index){return _c('NextWeekActivitiesList',{key:'nextWeekActivities' + index,attrs:{"title":wf.message,"id":wf.id},on:{"deleteWayForward":(wfId) => _vm.deleteWayForward(wfId)}})}),1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',_vm._l((_vm.$store.getters.getAttachments),function(item){return _c('v-col',{key:item.filePath,staticStyle:{"padding":"0"},attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-list-item',{attrs:{"dense":""}},[_c('v-list-item-content',{staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"d-flex justify-space-between align-center px-4 mb-1 py-1 rounded-lg",staticStyle:{"background":"#ddd","min-height":"40px"}},[_c('v-list-item-icon',[_c('img',{attrs:{"src":_vm.$store.getters.getIcons[
                            item.fileExtension.split('.')[1]
                          ]
                            ? _vm.$store.getters.getIcons[
                                item.fileExtension.split('.')[1]
                              ]
                            : _vm.$store.getters.getIcons['file'],"height":"30"}})]),_c('v-list-item-action-text',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.fileName)),_c('br'),_c('span',{staticClass:"grey--text"},[_c('b',[_vm._v("Created On:")]),_vm._v(" "+_vm._s(item.createdDate.substr(0, 10)))])]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.backend_url + item.filePath,"download":item.fileName,"target":"_blank"}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-download-box")])],1),_c('v-btn',{attrs:{"text":"","fab":"","small":""},on:{"click":function($event){return _vm.deleteAttachment(item.attachmentId)}}},[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-delete")])],1)],1)])],1)],1)}),1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }