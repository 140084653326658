// import { apolloClient } from "@/vue-apollo";
// import DeleteProjectCategory from "@/GraphQL/ProjectCategories/DeleteProjectCategory.gql";
// import allCategories from "@/GraphQL/ProjectCategories/allCategories.gql";
// eslint-disable-next-line no-unused-vars
import CreateProjectCategory from "@/GraphQL/ProjectCategories/CreateProjectCategory.gql";

export default {
  state: {
    projectCategory: [],
    permissionGroups: [],

  },
  getters: {
    getPermissionGroups: (state) => state.permissionGroups,
  },
  mutations: {

  },
  actions: {
    // async loadProjectCategories(context) {
    //   context.commit("clearProjectCategories");
    //   context.commit("setLoadingState", true);
    //   apolloClient
    //     .query({
    //       query: allCategories,
    //     })
    //     .then((response) => {
    //       response.data.allCategories?.forEach((element) => {
    //         let data = {
    //           uuid: element.uuid,
    //           categoryName: element.categoryName,
    //           creationDate: element.creationDate,
    //         };
    //         context.commit("loadProjectCategories", data);
    //       });
    //     }).finally(()=>{context.commit("setLoadingState", false);});
    // },
    // fetchPermissionGroups: async (context) => {
    //   context.commit("setLoadingState", true);
    //   await apolloClient
    //     .query({fetchPolicy: "network-only",
    //       query: getSystemPermissionsList,
    //     })
    //     .then((output) => {
    //       let { response, data } = output.data.getSystemPermissionsList;
    //       if (response.status) {
    //         context.commit("addPermissionGroups", data);
    //       } else {
    //         context.dispatch("displayErrorSuccessMessages", {
    //           error: true,
    //           code: response?.code,
    //           message: response?.message,
    //         });
    //       }
    //     }).finally(() => {
    //       context.commit("setLoadingState", false);
    //     });
    // },
    // getUserRolesAndPermissions: async (context) => {
    //   context.commit("setLoadingState", true);
    //   await apolloClient
    //     .query({fetchPolicy: "network-only",
    //       query: getUserRolesAndPermissions,
    //     })
    //     .then((output) => {
    //       let { response, data } = output.data.getUserRolesAndPermissions;
    //       if (response.status) {
    //         context.commit("setMyRolesAndPermissions", data);
    //       } else {
    //         context.dispatch("displayErrorSuccessMessages", {
    //           error: true,
    //           code: response?.code,
    //           message: response?.message,
    //         });
    //       }
    //     }).finally(() => {
    //       context.commit("setLoadingState", false);
    //     });
    // },

  },
};
