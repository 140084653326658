<template>
  <div class="main-container">
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="12">
          <div class="d-flex justify-space-between mb-4">
            <v-btn
              to="/project/projects"
              color="primary"
              class="text-capitalize rounded-lg"
            >
              <v-icon small>mdi-arrow-left-circle</v-icon>
              Projects
            </v-btn>
            <v-spacer></v-spacer>
            <span>
              <v-btn
                small
                class="mx-2"
                fab
                color="primary"
                dark
                dense
                @click="createUpdateProjectCategoryDialog = true"
              >
                <v-icon small> mdi-plus</v-icon>
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <CreateProjectCategory
                    v-bind="attrs"
                    v-on="on"
                    :CreateUpdateProjectCategoryDialog="
                      createUpdateProjectCategoryDialog
                    "
                    :CreateUpdateProjectCategoryData="
                      createUpdateProjectCategoryData
                    "
                    @close="createUpdateProjectCategoryDialog = false"
                  >
                  </CreateProjectCategory>
                </template>
                <span>Create New Project Category</span>
              </v-tooltip>
            </span>
          </div>
          <h3 class="">Project Category</h3>
        </v-col>
      </v-row>
      <v-row> </v-row>
      <br />
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="$store.getters.getProjectCategories"
              :search="search"
              class="elevation-10"
            >
              <template v-slot:item.SN="{ item, index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.creationDate="{ item }">
                {{ item.creationDate | formatDate }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-menu dense offset-x :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      right
                      :nudge-width="200"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="UpdateProjectCategory(item)">
                      <v-list-item-icon>
                        <v-icon small>mdi-pencil</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title> Edit Category</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="DeleteProjectCategory(item.uuid)">
                      <v-list-item-icon>
                        <v-icon color="#f83e70" left small>mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>Delete Category</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CreateProjectCategory from "../projectCategory/CreateProjectCategory.vue";
export default {
  data() {
    return {
      dialog: false,
      createUpdateProjectCategoryDialog: false,
      createUpdateProjectCategoryData: null,
      categoryNameRules: [
        (v) => !!v || "ProjectCategories Name is required",
        (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
      ],
      category: {},
      allCategories: [],
      chips: "",
      search: "",
      headers: [
        { text: "Sn", align: "start", sortable: false, value: "SN" },
        {
          text: "ProjectCategories Name",
          value: "categoryName",
          sortable: false,
        },
        {
          text: "Create Date",
          value: "creationDate",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    sortBy(prop) {
      this.projects.sort((a, b) => (a[prop] < b[prop] ? -1 : 1));
    },
    DeleteProjectCategory(uuid) {
      let data = {
        uuid: uuid,
      };
      this.$store.dispatch("deleteProjectCategories", data);
    },
    editCategory(cat) {
      this.category = cat;
    },
    UpdateProjectCategory(item) {
      this.createUpdateProjectCategoryDialog = true;
      this.createUpdateProjectCategoryData = item;
    },
  },
  components: { CreateProjectCategory },
  computed: {
    username() {
      return localStorage.getItem("loggedusername");
    },
  },
  mounted() {
    this.$store.dispatch("loadProjectCategories");
  },
};
</script>

<style>
.project.completed {
  border-left: 4px solid #3cd1c2;
}
.project.ongoing {
  border-left: 4px solid orange;
}
.project.overdue {
  border-left: 4px solid tomato;
}
#chip.v-chip.completed {
  background: #3cd1c2;
}
#chip.v-chip.ongoing {
  background: #ffaa2c;
}
#chip.v-chip.overdue {
  background: #f83e70;
}
.no-uppercase {
  text-transform: unset !important;
}
.main-container {
  padding: 30px 50px;
  height: 100%;
  width: 100%;
  background: #fff;
}
.w-100 {
  width: 100% !important;
}
</style>
