<template>
  <v-container fluid class="px-10 py-5">
    <div class="d-flex justify-space-between">
      <h2 class="">All Projects Report</h2>
    </div>

    <div class="row  mt-5">
      <div class="col-md-4">
        <v-menu v-model="startDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
          offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="startDate" label="Start Date" prepend-icon="mdi-calendar-clock" readonly v-bind="attrs"
              v-on="on" outlined small dense>
            </v-text-field>
          </template>
          <v-date-picker v-model="startDate" @input="startDateMenu = false"></v-date-picker>
        </v-menu>
      </div>

      <div class="col-md-4">
        <v-menu v-model="endDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
          offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="endDate" label="Expected End Date" prepend-icon="mdi-calendar-end" readonly
              v-bind="attrs" v-on="on" outlined small dense></v-text-field>
          </template>
          <v-date-picker v-model="endDate" @input="endDateMenu = false"></v-date-picker>
        </v-menu>
      </div>
      <div class="col-md-4">
        <span>
          <v-btn color="primary" class="mr-2" @click="export_data_to_exel" outlined
            v-if="$store.getters.getUserState.isStaff">
            <!-- <v-icon v-html="'mdi-download-circle'" /> -->
            <span class="text-center text-none"><v-icon class="mr-3">mdi-microsoft-excel</v-icon>Export</span>
            <!-- Export -->
          </v-btn>

          <v-btn color="primary" dark @click="sendReportToEmail()" v-if="$store.getters.getUserState.isStaff">
            <v-icon small>mdi-share</v-icon>
            Send to e-Mail
          </v-btn>
        </span>
      </div>
    </div>
    <v-card class="mt-5 elevation-5 rounded-lg" outlined>
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="allProjects" :search="search">
        <template v-slot:item.SN="{ item }">
          {{ allProjects.indexOf(item) + 1 }}
        </template>
        <template v-slot:item.thisWeek="{ item }">
          <ul>
            <span v-for="(desc,index) in item.taskProject" :key="'thisweektasks' + index">
              <li v-if="!desc.isNextWeekTask && desc.taskStatus" style="list-style: circle">
                {{ desc.taskDescription }}
              </li>
            </span>
          </ul>
        </template>
        <template v-slot:item.nextWeek="{ item }">
          <span v-for="(desc,index) in item.taskProject" :key="'nextweektasks' + index">
            <li v-if="desc.isNextWeekTask" style="list-style: circle">
              {{ desc.taskDescription }}
            </li>
          </span>
        </template>

        <template v-slot:item.projectMembers="{ item }">
          <ol>
            <li v-for="projectMember in item.projectMembers" :key="'users' + projectMember.username" class="my-0 py-0">
              {{ projectMember.firstName }} {{ projectMember.lastName }}
              <v-icon v-if="
                item.projectLeader.find(
                  ({ username }) => username === projectMember.username
                )
              " color="primary">mdi-crown-circle</v-icon>
            </li>
          </ol>
        </template>
        <template v-slot:item.projectName="{ item }">
          <h2><b>Name:</b> {{ item.projectName }}</h2>
          <p><b>Description:</b> {{ item.projectDescription }}</p>
          <p><b>Client:</b> {{ item.projectClient }}</p>
          <p class="text-caption">
            <b>Start-date:</b> {{ item.projectStartDate }}
          </p>
          <p class="text-caption"><b>End-date:</b> {{ item.projectEndDate }}</p>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import users from "../../../GraphQL/Users/allUser.gql";
import ExportExcel from "../../../GraphQL/Report/ExportExcel.gql";
import allProjects from "../../../GraphQL/Projects/allProjects.gql";
export default {
  apollo: {
    users: {
      query: users,
      update: (data) => data.users.edges,
    },
    allProjects: {
      query: allProjects,
      update: (data) => data.allProjects,
    },
  },
  components: {},
  data() {
    return {
      search: null,
      // StartDatedate: new Date(
      //   Date.now() - new Date().getTimezoneOffset() * 60000
      // )
      //   .toISOString()
      //   .substr(0, 10),
      // date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),

      ///
      startDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      endDate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),

      ///
      menu: false,
      modal: false,
      menu1: false,
      menu2: false,
      startDateMenu: false,
      endDateMenu: false,
      allProjects: [],
      headers: [
        { text: "S/N", align: "start", sortable: false, value: "SN" },
        {
          text: "Project Details",
          value: "projectName",
          sortable: false,
          width: "20%",
        },
        {
          text: "Project Members",
          value: "projectMembers",
          sortable: false,
          width: "20%",
        },
        {
          text: "Actions Taken During the Week",
          value: "thisWeek",
          sortable: false,
          width: "20%",
        },
        {
          text: "Plan for the Week",
          value: "nextWeek",
          sortable: false,
          width: "20%",
        },
        { text: "Status", value: "projectStatus.projectStatusName", sortable: true },
        {
          text: "Project Comments",
          value: "projectComments",
          sortable: false,
          width: "20%",
        },
      ],
      heads: [
        "Sn",
        "Project Activity",
        "Client/Institution",
        "Description",
        "Project Team",
        "Status",
        "Project Comments",
        "Start Date",
        "Expected Completion Date",
      ],
    };
  },
  methods: {
    sendReportToEmail() {
      this.$apollo.mutate({
        mutation: ExportExcel,
        variables: {
          sendAttachment: true,
        },
        update: (cache, { data }) => {
          console.log(data);
          if (!data.ExportExcel.errors) {
            this.$swal.fire({
              position: "top-end",
              icon: "info",
              title: "Report Success Reported",
              showConfirmButton: false,
              timer: 1500,
              height: "100px",
            });
          } else {
            let errors = [];
            for (let e of data.ExportExcel.errors[0].message) {
              // console.log(data.ExportExcel.errors[0].message);
              console.log(errors.push(e));
            }
            this.errors = errors;
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: false,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: "Report details didn't match!",
            });
          }
          this.dialog = false;
        },
      });
    },
    export_data_to_exel() {
     
      let data = {
        startDate: this.startDate,
        endDate: this.endDate,
        sendAttachment: true,
      };
      this.$apollo.mutate({
        mutation: ExportExcel,
        variables: data,
        update: (cache, { data }) => {
          console.log(data);
          if (!data.ExportExcel.errors) {
            console.log("here ", data);
            // this.$apollo.queries.allProjects.refresh(); // refresh projects users_dts
            this.$swal.fire({
              position: "top-end",
              icon: "info",
              title: "Report Success Reported",
              showConfirmButton: false,
              timer: 1500,
              height: "100px",
            });
            window.open(
              process.env.VUE_APP_EXPORT_URL + data.ExportExcel.fullFileUrl,
              "_blank"
            );
          } else {
            let errors = [];
            for (let e of data.ExportExcel.errors[0].message) {
              console.log(data.ExportExcel.errors[0].message);
              console.log(errors.push(e));
            }
            this.errors = errors;
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: false,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: "Report details didn't match!",
            });
          }
          this.dialog = false;
        },
      });
    },
    getTaskProject(tasks) {
      return tasks.map(function (task) {
        return task.taskDescription;
      });
    },
  },
};
</script>
