import {apolloClient} from "@/vue-apollo";
import Vue from 'vue'
import createUpdateRole from "@/GraphQL/RoleManagement/createUpdateRole.graphql"
import deleteRole from "@/GraphQL/RoleManagement/deleteRole.graphql"
import getAllRoles from "@/GraphQL/RoleManagement/getAllRoles.graphql"
import getAllPermissions from "@/GraphQL/RoleManagement/getAllPermissions.graphql"
import addRoleToUser from "@/GraphQL/RoleManagement/addRoleToUser.graphql"
import getRoleByUuid from "@/GraphQL/RoleManagement/getRoleByUuid.graphql"
import removeUserFromRole from "@/GraphQL/RoleManagement/removeUserFromRole.graphql";

export default {
    state : {
        roles : [],
        permissions : [],
        createRoleBool : false,
        dialogAssignRoleUsers: false,
        viewRole : {},
    },
    getters: {
        getRoles  : (state) => (state.roles),  
        getPermissions  : (state) => (state.permissions),  
        getCreateRoleBool : (state) => (state.createRoleBool),
        getDialogAssignRoleUsers : (state) => (state.dialogAssignRoleUsers),
        getViewRole : (state) => (state.viewRole),
    },
    mutations : {
        addRole  : (state, roles) => (state.roles.push(roles)),
        addPermissions  : (state, permissions) => (state.permissions.push(permissions)),
        setCreateRoleBool :   (state, value) => (state.createRoleBool = value),
        setDialogAssignRoleUsers :   (state, value) => (state.dialogAssignRoleUsers = value),
        setViewRole :   (state, viewRole) => (state.viewRole=viewRole),
        deleteRole (state, data){
            // console.log("This is deleted",data);
            state.roles.splice(state.roles.findIndex(({ uuid }) => uuid === data.uuid),
          1
        )},
        clearRole :   (state) => (state.roles=[]),
    },
    actions : {
        async loadRoles(context){
            context.commit('clearRole');
            context.commit("setLoadingState", true);

            apolloClient.query({
                query:getAllRoles,
            }).then((response)=>{
                response.data.getAllRoles.forEach(singleRole=>{
                    context.commit("addRole", singleRole)
                })
            }).finally(()=>{
                context.commit("setLoadingState", false);
            })
        },
        async loadAllPermissions(context){
            context.commit("setLoadingState", true);
            apolloClient.query({
                query:getAllPermissions,
            }).then((response)=>{
                response.data.getAllPermissions.forEach(singlePermission=>{
                    context.commit("addPermissions", singlePermission)
                })
            }).finally(()=>{
                context.commit("setLoadingState", false);
            })
        },
        async fetchViewRole(context, uuid){
            context.commit("setLoadingState", true);
            apolloClient.query({
                query:getRoleByUuid,
                variables: {uuid}
            }).then((response)=>{
                let data={
                    uuid:response.data.getRoleByUuid[0].uuid,
                    displayName:response.data.getRoleByUuid[0].roleName,
                    usersList:response.data.getRoleByUuid[0].userandroleSet
                }
                // console.log(data);
                context.commit('setViewRole',data);
            }).finally(()=>{
                context.commit("setLoadingState", false);
            })
        },
        async createUpdateRole(context,data){
            apolloClient.mutate({
                mutation:createUpdateRole,
                variables:data
            }).then((response)=>{
                let {createdRole, success} = response.data.CreateUpdateRole
                
                // console.log(success);
                if(!success){
                    Vue.swal({
                      title:"Error!",
                      text:"Oops! Error Occured",
                      icon:"error"
                    })
                }else{
                    Vue.swal({
                        title:"Success!",
                        text:`Role ${data.uuid?"Updated":"Created"} Successfully!`,
                        icon:"success"
                    })
                }
                context.commit('addRole',createdRole)
                return data
            })
        },
        async addRoleToUser(context,data){
            apolloClient.mutate({
                mutation:addRoleToUser,
                variables:{...data}
            }).then((response)=>{
                let {createdRole, success} = response.data.addRoleToUser
                
                // console.log(success);
                if(!success){
                    Vue.swal({
                        title:"Error!",
                        text:`Oops! Error in assigning role to User(s)`,
                        icon:"error"
                    })
                }else{
                    Vue.swal({
                        title:"Success!",
                        text:`User(s) have been assigned role(s) Successfully!`,
                        icon:"success"
                    })
                }
                
                return createdRole
            })
        },

        async deleteRole(context,data){
            apolloClient.mutate({
                mutation:deleteRole,
                variables: data
            }).then((response)=>{
                let success= response.data.deleteRole.success
                // console.log(code);
                if(!success){
                    Vue.swal({
                        title:"Error!",
                        text:`Error in deleting role!`,
                        icon:"error"
                    })
                }else{
                    context.commit('deleteRole',data)
                    Vue.swal({
                        title:"Success!",
                        text:`Role deleted Successfully`,
                        icon:"success"
                    })
                }
            })
        },
        async removeUserFromRole(context,data){
            apolloClient.mutate({
                mutation:removeUserFromRole,
                variables: data
            }).then((response)=>{
                let success= response.data.removeUserFromRole.success
                // console.log(code);
                if(!success){
                    Vue.swal({
                        title:"Error!",
                        text:`Error in removing user from role!`,
                        icon:"error"
                    })
                }else{
                    context.commit('removeUserFromRole',data)
                    Vue.swal({
                        title:"Success!",
                        text:`Removed user from role Successfully!`,
                        icon:"success"
                    })
                }
            })
        },
    }
}