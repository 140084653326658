<template>
  <v-dialog v-model="dialog" ersistent max-width="400px" persistent>

    <v-card>
      <v-card-title>
        <span class="grey--text">Add Category</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              class="mx-3"
              color="#f83e70"
              dark
              small
              @click="$emit('close')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-close-octagon</v-icon>
            </v-btn>
          </template>
          <span>Cancel</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Category Name"
              v-model="categoryName"
              dense
              required
              prepend-icon="mdi-folder-multiple"
              outlined
              small
              @keyup.enter="CreateProjectCategory"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>

          <v-tooltip bottom color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                @click="CreateProjectCategory();"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>mdi-send</v-icon>
              </v-btn>
            </template>
            <span>Submit you data !</span>
          </v-tooltip>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "CreateUpdateProjectCategoryDialog",
    "CreateUpdateProjectCategoryData",
  ],
  data: () => ({
    uuid: null,
    dialog: false,
    categoryNameRules: [
      (v) => !!v || "ProjectCategories Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    categoryName: "",
    chips: "",
  }),
  methods: {
    CreateProjectCategory() {
      let data = {
        uuid: this.uuid,
        categoryName: this.categoryName,
      };
     
      this.$store.dispatch("createProjectCategory", data);
      this.uuid=null
      this.categoryName=null
      this.$emit("close");
    },
    preventKeyInputs(e) {
      e.preventDefault();
    },
  },
  watch: {
    CreateUpdateProjectCategoryDialog(newValues) {
      this.dialog = newValues;
    },
    CreateUpdateProjectCategoryData(newValues) {
      this.categoryName = newValues.categoryName;
      this.uuid = newValues.uuid;
    },
  },
};
</script>
<style scoped></style>
