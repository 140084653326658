<template>
  <v-dialog v-model="dialog" ersistent max-width="400px" persistent>

    <v-card>
      <v-card-title>
        <span class="grey--text">Add Maturity Level</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab class="mx-3" color="#f83e70" dark small @click="$emit('close')" v-bind="attrs" v-on="on">
              <v-icon small>mdi-close-octagon</v-icon>
            </v-btn>
          </template>
          <span>Cancel</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field label="Project Maturity" v-model="projectMaturityLevelName" dense required
              prepend-icon="mdi-folder-multiple" outlined small @keyup.enter="CreateProjectMaturity"></v-text-field>
          </v-col>
          <v-spacer></v-spacer>

          <v-tooltip bottom color="success">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark @click="CreateProjectMaturity();" v-bind="attrs" v-on="on">
                <v-icon small>mdi-send</v-icon>
              </v-btn>
            </template>
            <span>Submit you data !</span>
          </v-tooltip>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: [
    "CreateUpdateProjectMaturityDialog",
    "CreateUpdateProjectMaturityData",
  ],
  data: () => ({
    projectMaturityLevelUniqueId: null,
    dialog: false,
    maturityNameRules: [
      (v) => !!v || "Project Maturity  Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    projectMaturityLevelName: "",
    chips: "",
  }),
  methods: {
    CreateProjectMaturity() {
      let data = {
        input: {
          projectMaturityLevelUniqueId: this.projectMaturityLevelUniqueId,
          projectMaturityLevelName: this.projectMaturityLevelName,
        }
      };
      
      this.$store.dispatch("createProjectMaturity", data);
      this.$store.dispatch("loadProjectMaturity");
      this.projectMaturityLevelName = null
      this.projectMaturityLevelName = null
      this.$emit("close");
    },
    preventKeyInputs(e) {
      e.preventDefault();
    },
  },
  watch: {
    CreateUpdateProjectMaturityDialog(newValues) {
      this.dialog = newValues;
    },
    CreateUpdateProjectMaturityData(newValues) {
      this.projectMaturityLevelUniqueId = newValues.projectMaturityLevelUniqueId;
      this.projectMaturityLevelName = newValues.projectMaturityLevelName;
    },
  },
};
</script>
<style scoped>

</style>

