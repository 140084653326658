import Vue from "vue";
import VueRouter from "vue-router";
import Projects from "../components/Projects/weris/Projects.vue";
import DashBoard from "../components/Projects/weris/DashBoard.vue";
import Teams from "../components/Projects/weris/Category.vue";
import Home from "../components/Projects/weris_projects/Home.vue";
import PageNotFound from "../components/Projects/weris_projects/PageNotFound.vue";
import MainProject from "../components/Projects/weris/MainProject.vue";
import Login_Page from "../components/Authentication/LoginPage.vue";
import ReportingPage from "../components/Reporting/Report_Main/Report_Main.vue";
import My_Action from "../components/Reporting/Report_Main/My_Action.vue";
import ProjectDetailedView from "../components/Projects/weris/ProjectDetailedView.vue";
import More_Project from "../components/Projects/weris/More_About_Project.vue";
import Reporting_Page from "../components/Reporting/Report_Main/Reporting_Page.vue";
import Projects_Report from "../components/Projects/weris/ProjectReports.vue";
import Status from "../components/Projects/weris/Status.vue";
import maturityLevel from "../components/Projects/weris/MaturityLevel.vue";
import Project_Report_Export from "../components/Reporting/Report_Main/Reporting_Page_Export.vue";
import Manager_Summary_Report from "../components/Projects/weris/ManagerSummary.vue";
import SuccessfulActivation from "@/components/Authentication/SuccessfulActivation";
import store from "@/store/index.js";

Vue.use(VueRouter);

const routes = [
  //  redirectly the home page to be landing page
  {
    path: "/",
    name: "Login Page",
    component: Login_Page,
  },
  {
    path: "/project",
    name: "MainProject",
    meta: {
      permissions: [""],
      requireAuth: true,
    },
    component: MainProject,
    children: [
      {
        path: "projects/:search",
        name: "Projects Search",
        component: Projects,
      },
      {
        path: "projects",
        name: "Projects",
        component: Projects,
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: DashBoard,
      },
      {
        path: "/users",
        name: "Users",
        component: () => import("@/components/UserManagement/index.vue"),
        meta: {
          requireAuth: true,
          permissions: ["staff"],
        },
      },
      {
        path: "roles",
        name: "Roles",
        component: () => import("@/components/RoleManagement/index.vue"),
        meta: {
          requireAuth: true,
          permissions: ["staff"],
        },
      },
      {
        path: "role/:roleUuid",
        name: "RoleView",
        component: () => import("@/components/RoleManagement/view.vue"),
        meta: {
          requireAuth: true,
          permissions: ["staff"],
        },
      },
      {
        path: "category",
        name: "category",
        meta: {
          permissions: ["staff"],
        },
        component: Teams,
      },
      {
        path: "maturity-level",
        name: "maturity-level",
        meta: {
          permissions: ["staff"],
        },
        component: maturityLevel,
      },
      {
        path: "status",
        name: "status",
        component: Status,
        meta:{
          permissions: ["staff"],

        }
      },
      {
        path: "project_report_export",
        name: "Project Reports",
        component: Project_Report_Export,
        meta: {
          // permissions: ["Can view report"],
        },
      },
      {
        path: "manager_summary_report",
        name: "Manager_Summary_Report",
        meta: {
          permissions: ["staff"],
        },
        component: Manager_Summary_Report,
      },
    ],
  },
  // PageNotFound
  {
    path: "/pagenotfound",
    name: "Page not Found",
    meta: {
      requiresAuth: false,
      permissions: []
    },
    component: PageNotFound,
  },
  {
    path: "/home_manager",
    name: "Home",
    meta: {
      permissions: ["staff"],
      requiresAuth: true,
    },
    component: Home,
  },
  // route for reporting
  {
    path: "/reporting",
    name: "ReportingPage",
    component: ReportingPage,
    meta: {
      // permissions: ["Can view report"],
      requiresAuth: true,
    },
  },
  //  route for my action
  {
    path: "/my_action",
    name: "My_Action",
    component: My_Action,
    meta: { requiresAuth: true },
  },
  //  route for more about about project status
  {
    path: "/project-detailed-view/:projectId",
    name: "Project Detailed View",
    component: ProjectDetailedView,
    meta: {
      // permissions: ["Can view task"],
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/project_report/",
    name: "Projects_Report",
    component: Projects_Report,
    meta: { requiresAuth: true },
    props: true,
  },
  //  more about project implementation
  {
    path: "/more_about_project",
    name: "More_Project",
    component: More_Project,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/activate/:uuid/",
    name: "SuccessfulActivation",
    component: SuccessfulActivation,
    meta: {
      requiresAuth: false,
    },
  },

  //  more about project implementation
  {
    path: "/reporting_page/:projectId/:projectTitle",
    name: "Reporting Page",
    component: Reporting_Page,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NOT_FOUND",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  let isAuthenticated = store.getters.getTokenState || null;
  // let canSeeManagerView;
  // to.meta?.permissions?.includes("staff") ?
  //   (canSeeManagerView = store.getters.getUserState.isStaff) : (canSeeManagerView = false);
  store.commit("setLoadingState", true);
  if (isAuthenticated == null && to.name !== "Login Page") {
    if (to.name === "SuccessfulActivation") {
      setTimeout(() => {
        next();
      }, 100);
    } else {
      next({ name: "Login Page" });
    }
  } else if (isAuthenticated !== null) {
    if (to.name === "Login Page") {
      next("project/dashboard");
    } else {
      if(to.meta?.permissions?.includes("staff")){
        if(store.getters.getUserState.isStaff){
          // alert("This is a staff")
        next()
        }else{
          next({ name: "Page not Found" });
        }
      }else
      next()
    }
  } else {
      next();
  }
});
router.afterEach(()=>{
  setTimeout(() => {
    store.commit("setLoadingState", false);
  }, 500);
})
export default router;
