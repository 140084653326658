<template>
      <v-container class="fill-height">
        <v-row justify="center" align="center">
          <v-col cols=12 sm=12 md=6 lg=5 >
            
            <v-card class="pa-5 text-center rounded-lg">
              <v-avatar>
                <v-img src="../../assets/images/app_anim.gif" color="primary" size="90">
                </v-img>
              </v-avatar>
              <h1 class="text-center ">Weris</h1>
              <p class="grey--text">Weekly Report Information System</p>
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-row>
                    <v-col cols="12">
                      <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                          <v-text-field
                            v-model="username"
                            :rules="emailRules"
                            label="Email"
                            required
                            outlined
                            class="rounded-lg"
                          />

                          <v-text-field
                            v-model="password"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show1 ? 'text' : 'password'"
                            :rules="passwordRules"
                            label="Password"
                            outlined
                            class="rounded-lg"
                            @click:append="show1 = !show1"
                            v-on:keyup.enter="handleLogin"
                            required
                          />
                          <div class="text-center">
                            <div class="d-flex justify-space-between align-center">
                              <v-checkbox
                              v-model="rememberMe"
                              label="Remember Me"
                              class=""
                            ></v-checkbox>
                         
                            <a
                              @click="step++"
                              class="mb-1"
                              color="primary"
                              style="cursor: pointer"
                            >
                              Forgot Password?
                            </a>
                            </div>
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-btn
                                  color="primary"
                                  @click="handleLogin"
                                  class="no-uppercase rounded-lg h-100"
                                  style="width: 100%;height:42px !important"
                                  dark
                                  >LOGIN</v-btn
                                >
                              </v-col>
                            </v-row>
                          </div>
                        </v-form>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-window-item>
                <v-window-item :value="2">
                  <v-row class="fill-height">
                    <v-col cols="12">
                      <div class="text-center">
                        <h4
                          @click="step--"
                          style="cursor: pointer"
                          color="primart--text"
                        >
                          Sign in Again
                        </h4>
                      </div>
                      <v-card-text class="mt-8" rounded>
                        <v-form>
                          <v-text-field
                            small
                            dense
                            v-model="email"
                            label="user@gmail.com"
                            prepend-icon="mdi-email"
                            color="primary--text"
                            :rules="emailRules"
                          />
                        </v-form>

                        <br /><br />
                        <div class="text-center mt-n5">
                          <v-btn
                            class="no-uppercase"
                            rounded
                            color="primary"
                            dark
                            @click="handleSubmitEmail"
                            >Submit</v-btn
                          >
                        </div>
                      </v-card-text>
                    </v-col>
                  </v-row>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
</template>

<script>
import auth from "../../GraphQL/Auth/auth.gql";
import router from "../../router";
export default {
  data: () => ({
    step: 1,
    show1:false,
    rememberMe:false,
    valid: false,
    username: "",
    usernameRules: [
      (v) => !!v || "User name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    password: "",
    passwordRules: [(v) => !!v || "Password is required"],
    email: "",
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+\..+/.test(v) || "e-mail must be valid",
    ],
    colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
  }),

  props: {
    source: String,
  },
  methods: {
    handleLogin(e) {
      e.preventDefault(e);
      let data = {
        username: this.username,
        password: this.password,
      };
      this.$apollo.mutate({
        mutation: auth,
        variables: data,
        update: (cache, { data }) => {
          if (data.tokenAuth.success) {
            //CHECK ALL PERRMISSIONS FROM THE STORE //
            // this.$store.dispatch("fetchUserPermissions");
            //
            // localStorage.setItem("token", data.tokenAuth.token);
            // localStorage.setItem("loggedUserId", data.tokenAuth.user.id);
            // localStorage.setItem(
            //   "loggedusername",
            //   data.tokenAuth.user.username
            // );
            this.$store.commit('setUserState',data.tokenAuth.user)
            this.$store.commit("setTokenState", data.tokenAuth.token);
            this.$store.commit('setRefreshTokenState',data.tokenAuth.refreshToken)
            // manager and BA ROUTE
            if (data.tokenAuth.user.isStaff) {
              router.push("/home_manager");
            } else if (!data.tokenAuth.user.isStaff) {
              // team members ROUTE
              router.push("/project/dashboard");
            } else {
              // admin ROUTE
              router.push("/admin_page");
            }
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "success",
              title: "Signed in successfully",
            });
          } else {
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              border: "50px",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "warning",
              color: "danger",
              title: "Credential didn't match!",
            });
          }
        },
      });
    },
    handleSubmitEmail(e) {
      e.preventDefault();
      this.$refs.form.validate();
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "warning",
        title: "credential didn't match!",
      });
    },
  },
};
</script>
<style>
.no-uppercase {
  text-transform: unset !important;
}
.login-illustration{
  background-image: url("../../assets/images/login-illustration.png");
  background-size: cover;
  
}
</style>
