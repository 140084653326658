import { apolloClient } from "@/vue-apollo";
import DeleteProject from "@/GraphQL/Projects/DeleteProject.gql";
import allProjectsOfAParticularUser from "@/GraphQL/Projects/allProjectsOfAParticularUser.gql";
// eslint-disable-next-line no-unused-vars
import CreateUpdateProject from "@/GraphQL/Projects/CreateUpdateProject.gql"

export default {
  state: {
    project: [],
  },
  getters: {
    getProjects: (state) => state.project,
  },
  mutations: {
    loadProject(state, projectDetails) {
      if (state.project.find(({ projectId }) => projectId === projectDetails.projectId)) {
        
        state.project.find(({ projectId }) =>projectId === projectDetails.projectId).projectClient =projectDetails.projectClient;
        state.project.find(({ projectId }) => projectId === projectDetails.projectId).projectDescription = projectDetails.projectDescription;
        state.project.find(({ projectId }) => projectId === projectDetails.projectId).projectEndDate = projectDetails.projectEndDate;
        state.project.find(({ projectId }) => projectId === projectDetails.projectId).projectLeadersId = projectDetails.projectLeadersId;
        state.project.find(({ projectId }) => projectId === projectDetails.projectId).projectMembers = projectDetails.projectMembers;
        state.project.find(({ projectId }) => projectId === projectDetails.projectId).projectName = projectDetails.projectName;
        state.project.find(({ projectId }) => projectId === projectDetails.projectId).projectStartDate = projectDetails.projectStartDate;
        state.project.find(({ projectId }) => projectId === projectDetails.projectId).projectStatus = projectDetails.projectStatus;
        state.project.find(({ projectId }) => projectId === projectDetails.projectId).projectCategory = projectDetails.projectCategory;
      } else {
        state.project.push(projectDetails);
      }
    },
    clearProject(state) {
      state.project = [];
    },
    deleteProject(state, data) {
      state.project.splice(
        state.project.findIndex(
          ({ projectId }) =>
            projectId === data.projectId
        ),
        1
      );
    },
  },
  actions: {
    async loadProject(context) {
      context.commit("clearProject");
      context.commit("setLoadingState", true);
      apolloClient
        .query({
          query: allProjectsOfAParticularUser,
        })
        .then((response) => {
          response.data.allProjectsOfAParticularUser?.forEach((element) => {
            let data = {
              projectId: element.projectId,
              projectName: element.projectName,
              projectDescription: element.projectDescription,
              projectStatus: element.projectStatus,
              projectClient: element.projectClient,
              projectEndDate: element.projectEndDate,
              projectStartDate: element.projectStartDate,
              projectComments: element.projectComments,
              projectLeader: element.projectLeader,
              projectCategory: element.projectCategory,
              projectMembers: element.projectMembers,
            };
            context.commit("loadProject", data);
          });
        }).finally(()=>{context.commit("setLoadingState", false);});
    },
    async CreateUpdateProject(context, data) {
      apolloClient
        .mutate({
          mutation: CreateUpdateProject,
          variables: data,
        })
        .then((response) => {
          // console.log(response.data.CreateUpdateProject);
          let dataTemp = {
            projectId: response.data.CreateUpdateProject.project.projectId,
            projectName: response.data.CreateUpdateProject.project.projectName,
            projectDescription: response.data.CreateUpdateProject.project.projectDescription,
            projectStatus: response.data.CreateUpdateProject.project.projectStatus,
            projectClient: response.data.CreateUpdateProject.project.projectClient,
            projectEndDate: response.data.CreateUpdateProject.project.projectEndDate,
            projectStartDate: response.data.CreateUpdateProject.project.projectStartDate,
            projectComments: response.data.CreateUpdateProject.project.projectComments,
            projectLeader: response.data.CreateUpdateProject.project.projectLeader,
            projectCategory: response.data.CreateUpdateProject.project.projectCategory,
            projectMembers: response.data.CreateUpdateProject.project.projectMembers,
          };
          context.commit("loadProject", dataTemp);
          let responseMessage = null;
          if (data.projectId) {
            responseMessage = "Project created successfully!";
          } else {
            responseMessage = "Project updated successfully!";
          }

          if (!response.data.CreateUpdateProject.success) {
            let messageResponse = {
              error: false,
              message: responseMessage,
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
    async deleteProject(context, data) {
      apolloClient
        .mutate({
          mutation: DeleteProject,
          variables: data,
        })
        .then((response) => {
          if (response.data.DeleteProject.success) {
            context.commit("deleteProject", data);
            let messageResponse = {
              error: false,
              message: "Project deleted successfully!",
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
  },
};
