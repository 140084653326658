<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card>
      <v-card-title>
        <span class="grey--text">Add Project</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="error">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              class="mx-3"
              color="#f83e70"
              dark
              small
              @click="$emit('close')"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon small>mdi-close-octagon</v-icon>
            </v-btn>
          </template>
          <span>Cancel</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Project Title"
                v-model="projectName"
                dense
                required
                prepend-icon="mdi-format-title"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                label="Client/Institutions"
                v-model="projectClient"
                required
                :rules="projectInstitutionsRules"
                prepend-icon="mdi-office-building"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Project Description"
                v-model="projectDescription"
                required
                prepend-icon="mdi-image-text"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-combobox
                large
                outlined
                v-model="projectMembers"
                chips
                clearable
                label="Project Team"
                multiple
                prepend-icon="mdi-account-group"
                :rules="projectMembersIdRules"
                :items="users"
                hide-selected
                :item-text="
                  (item) =>
                    item.firstName
                      ? `${item.firstName} ${item.lastName}`
                      : item.username
                "
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    @click="select"
                    @click:close="remove1(item)"
                  >
                    <v-avatar left class="primary text-capitalize white--text">
                      {{ item.username.charAt(0) }}
                    </v-avatar>
                    <strong>{{ item.firstName }} {{ item.lastName }}</strong
                    >&nbsp;
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" v-if="projectMembers.length > 0">
              <v-select
                small
                outlined
                multiple
                dense
                clearable
                single-line
                :rules="projectLeaderIdRules"
                v-model="projectLeadersId"
                placeholder="Project Leader*"
                prepend-icon="mdi-account-tie"
                :items="projectMembers"
                item-value="id"
                :item-text="
                  (item) =>
                    item.firstName
                      ? `${item.firstName} ${item.lastName}`
                      : item.username
                "
                @keypress="(event) => preventKeyInputs(event)"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :return-object="true"
                outlined
                v-model="projectCategory"
                :items="projectCategories"
                dense
                chips
                clearable
                label="Project Category"
                prepend-icon="mdi-format-list-checks"
                item-value="categoryId"
                :item-text="(item) => `${item.categoryName}`"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    small
                    @click="select"
                    @click:close="remove1(item)"
                  >
                    <strong>{{ item.categoryName }}</strong
                    >&nbsp;
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                :return-object="true"
                outlined
                v-model="projectStatusObj"
                :items="projectAllStatus"
                dense
                chips
                clearable
                label="Project Status"
                prepend-icon="mdi-format-list-checks"
                item-value="projectStatusUniqueId"
                :item-text="(item) => `${item.projectStatusName}`"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    small
                    @click="select"
                    @click:close="remove1(item)"
                  >
                    <strong>{{ item.projectStatusName }}</strong
                    >&nbsp;
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          
            <v-col cols="12">
              <v-autocomplete
                :return-object="true"
                outlined
                v-model="projectMaturityLevel"
                :items="projectMaturity"
                dense
                chips
                clearable
                label="Project Maturity"
                prepend-icon="mdi-format-list-checks"
                item-value="projectMaturityLevelUniqueId"
                :item-text="(item) => `${item.projectMaturityLevelName}`"
              >
                <template v-slot:selection="{ attrs, item, select, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    close
                    small
                    @click="select"
                    @click:close="remove1(item)"
                  >
                    <strong>{{ item.projectMaturityLevelName }}</strong
                    >&nbsp;
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="projectStartDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    dense
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="projectStartDate"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="projectEndDate"
                    label="Expected End Date"
                    prepend-icon="mdi-calendar-end"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    dense
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="projectEndDate"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-spacer></v-spacer>

            <v-col cols="12" sm="12">
              <div class="d-flex justify-end">
                <v-tooltip bottom color="success">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="text-none"
                      dark
                      @click="createProjects"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-check-circle</v-icon> Save
                    </v-btn>
                  </template>
                  <span>Submit you data !</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import users from "../../../GraphQL/Users/allUser.gql";
import allProjects from "../../../GraphQL/Projects/allProjects.gql";
import allCategories from "../../../GraphQL/ProjectCategories/allCategories.gql";
export default {
  apollo: {
    allProjects: {
      query: allProjects,
      update: (data) => data.allProjects,
    },
    allCategories: {
      query: allCategories,
      update: (data) => data.allCategories,
    },
  },
  props: ["CreateUpdateProjectDialog", "CreateUpdateProjectData"],
  data: () => ({
    users: [],
    dialog: false,
    projectId: null,
    projectTitleRules: [
      (v) => !!v || "Project Title is required",
      (v) => (v && v.length <= 10) || "Title must be less than 10 characters",
    ],
    projectDescriptionleRules: [
      (v) => !!v || "Project Description is required",
      (v) =>
        (v && v.length <= 10) || "Name Description be less than 10 characters",
    ],
    projectInstitutionsRules: [
      (v) => !!v || "Project Client is required",
      (v) => (v && v.length <= 10) || "Client must be less than 10 characters",
    ],
    projectLeaderIdRules: [
      (v) => !!v || "Project Leader is required",
      (v) => (v && v.length <= 50) || "leader must be less than 10 characters",
    ],
    projectMembersIdRules: [
      (v) => !!v || "Project Members is required",
      (v) => (v && v.length <= 10) || "leader must be less than 10 characters",
    ],
    projectClient: "",
    projectDescription: "",
    projectInstitutions: "",
    projectLeadersId: [],
    projectMembers: [],
    projectStatusItems: [],
    projectMaturityItems: [],
    projectStatusItems1: [
      "Research",
      "In Development",
      "In Production",
      "On Hold",
      "Other",
    ],
    projectCategory: [],
    projectMaturityLevel:[],
    projectName: "",
    chips: "",
    status: "",
    projectStartDate: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    projectEndDate: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    errors: [],
    menu: false,
    modal: false,
    startDateMenu: false,
    endDateMenu: false,
  }),
  methods: {
    printMemberValues() {
      // console.log(this.projectLeadersId);
      // console.log(this.projectMembers);
    },
    remove(item) {
      this.chips.splice(this.chips.indexOf(item), 1);
      this.chips = [...this.chips];
    },
    remove1(item) {
      this.projectMembers.splice(this.projectMembers.indexOf(item), 1);
      this.projectMembers = [...this.projectMembers];
    },
    createProjects() {
      let data = {
        projectId: this.projectId,
        projectClient: this.projectClient,
        projectDescription: this.projectDescription,
        projectEndDate: this.projectEndDate,
        projectStartDate: this.projectStartDate,
        projectLeadersId: [],
        projectMembers: [],
        projectName: this.projectName,
        projectCategoryId: this.projectCategory.uuid,
        projectStatusUniqueId: this.projectStatusObj.projectStatusUniqueId,
        projectMaturityLevelUniqueId: this.projectMaturityLevel.projectMaturityLevelUniqueId
      };
      this.projectMembers.map((member) =>
        data.projectMembers.push({ id: member.id })
      );

      this.projectLeadersId.map((member) =>
        data.projectLeadersId.push({ id: member })
      );
      this.$store.dispatch("CreateUpdateProject", data);
      this.projectClient = null;
      this.projectDescription = null;
      this.projectInstitutions = null;
      this.projectLeadersId = [];
      this.projectMembers = [];
      // this.projectStatus = null;
      this.projectCategory = [];
      this.projectMaturityLevel = [];
      this.projectStatusObj = [];
      this.projectName = null;
      this.projectStartDate = null;
      this.projectEndDate = null;
      this.projectId = null;
      this.$emit("close");
    },
    printStatus() {
      // console.log(this.projectStatus);
    },
    preventKeyInputs(e) {
      e.preventDefault();
    },
  },
  watch: {
    CreateUpdateProjectDialog(newValues) {
      this.dialog = newValues;
    },
    CreateUpdateProjectData(newValues) {
      console.log("new values",newValues);
      this.projectClient = newValues.projectClient;
      this.projectDescription = newValues.projectDescription;
      this.projectInstitutions = newValues.projectInstitutions;
      newValues.projectLeader.forEach((element) => {
        this.projectLeadersId.push(element.id);
      });
      this.projectMembers = newValues.projectMembers;
      this.projectStatusObj = newValues.projectStatusObj;
      this.projectCategory = newValues.projectCategory;
      this.projectMaturityLevel = newValues.projectMaturityLevel;
      // console.log("This is project ", newValues.projectCategory)
      this.projectName = newValues.projectName;
      this.projectStartDate = newValues.projectStartDate;
      this.projectEndDate = newValues.projectEndDate;
      this.projectId = newValues.projectId;
    },
  },
  mounted() {
    this.$store.dispatch("loadUsers");
    this.$store.dispatch("loadProjectCategories");
    this.$store.commit("setLoadingState", true);
    this.$apollo
      .query({
        query: users,
      })
      .then((response) => {
        // console.log(response.data.users.edges)
        response.data.users.edges.forEach((element) => {
          // console.log(element.node);
          this.users.push(element.node);
        });
      })
      .finally(() => {
        this.$store.commit("setLoadingState", false);
      });
  },
  computed: {
    usersList() {
      return this.$store.getters.getUsers;
    },
    projectCategories() {
      return this.$store.getters.getProjectCategories;
    },
    projectMaturity() {
      return this.$store.getters.getProjectMaturityLevel;
    },
    projectAllStatus() {
      return this.$store.getters.getProjectStatus;
    },

  },
};
</script>
<style scoped></style>
