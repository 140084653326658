import { apolloClient } from "@/vue-apollo";
import DeleteProjectCategory from "@/GraphQL/ProjectCategories/DeleteProjectCategory.gql";
import allCategories from "@/GraphQL/ProjectCategories/allCategories.gql";
// eslint-disable-next-line no-unused-vars
import CreateProjectCategory from "@/GraphQL/ProjectCategories/CreateProjectCategory.gql";

export default {
  state: {
    projectCategory: [],
  },
  getters: {
    getProjectCategories: (state) => state.projectCategory,
  },
  mutations: {
    loadProjectCategories(state, projectCategoryDetails) {
      if (
        state.projectCategory.find(
          ({ uuid }) => uuid === projectCategoryDetails.uuid
        )
      ) {
        state.projectCategory.find(
          ({ uuid }) => uuid === projectCategoryDetails.uuid
        ).categoryName = projectCategoryDetails.categoryName;
        state.projectCategory.find(
          ({ uuid }) => uuid === projectCategoryDetails.uuid
        ).creationDate = projectCategoryDetails.creationDate;
      } else {
        state.projectCategory.push(projectCategoryDetails);
      }
    },
    clearProjectCategories(state) {
      state.projectCategory = [];
    },
    deleteProjectCategories(state, data) {
      state.projectCategory.splice(
        state.projectCategory.findIndex(({ uuid }) => uuid === data.uuid),
        1
      );
    },
  },
  actions: {
    async loadProjectCategories(context) {
      context.commit("clearProjectCategories");
      context.commit("setLoadingState", true);
      apolloClient
        .query({
          query: allCategories,
        })
        .then((response) => {
          response.data.allCategories?.forEach((element) => {
            let data = {
              uuid: element.uuid,
              categoryName: element.categoryName,
              creationDate: element.creationDate,
            };
            context.commit("loadProjectCategories", data);
          });
        }).finally(()=>{context.commit("setLoadingState", false);});
    },
    async createProjectCategory(context, data) {
      apolloClient
        .mutate({
          mutation: CreateProjectCategory,
          variables: data,
        })
        .then((response) => {
          console.log(response.data.CreateProjectCategory);
          let dataTemp = {
            uuid: response.data.CreateProjectCategory.createdCategoryObject
              .uuid,
            categoryName:
              response.data.CreateProjectCategory.createdCategoryObject
                .categoryName,
            creationDate:
              response.data.CreateProjectCategory.createdCategoryObject
                .creationDate,
          };
          context.commit("loadProjectCategories", dataTemp);
          let responseMessage = null;
          if (data.uuid) {
            responseMessage = "Project Category created successfully!";
          } else {
            responseMessage = "Project Category updated successfully!";
          }

          if (!response.data.error) {
            let messageResponse = {
              error: false,
              message: responseMessage,
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
    async deleteProjectCategories(context, data) {
      apolloClient
        .mutate({
          mutation: DeleteProjectCategory,
          variables: data,
        })
        .then((response) => {
          if (response.data.DeleteProjectCategory.success) {
            context.commit("deleteProjectCategories", data);
            let messageResponse = {
              error: false,
              message: "ProjectCategory deleted successfully!",
            };
            context.dispatch("displayErrorSuccessMessages", messageResponse);
          } else {
            context.dispatch("displayErrorSuccessMessages", response.data);
          }
        });
    },
    // async CreateProjectCategory(context, data) {
    //   apolloClient
    //     .mutate({
    //       mutation: CreateProjectCategory,
    //       variables: data,
    //     })
    //     .then((response) => {
    //       // console.log(response.data.CreateProjectCategory);
    //       let dataTemp = {
    //         uuid: response.data.CreateProjectCategory.createdCategoryObject
    //           .uuid,
    //         categoryName:
    //         response.data.CreateProjectCategory.createdCategoryObject
    //           .categoryName,
    //         creationDate:
    //         response.data.CreateProjectCategory.createdCategoryObject
    //           .creationDate,
    //       };
    //       context.commit("loadProjectCategories", dataTemp);
    //       let responseMessage = null;
    //       if (data.uuid) {
    //         responseMessage = "Project Category created successfully!";
    //       } else {
    //         responseMessage = "Project Category updated successfully!";
    //       }
    //
    //       if (!response.data.error) {
    //         let messageResponse = {
    //           error: false,
    //           message: responseMessage,
    //         };
    //         context.dispatch("displayErrorSuccessMessages", messageResponse);
    //       } else {
    //         context.dispatch("displayErrorSuccessMessages", response.data);
    //       }
    //     });
    // },
  },
};
